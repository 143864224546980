import { FC } from "react";
import { ListItem } from "@mui/material";
import "./navigationBar.css";

import {
  NavigationItemButton,
  NavigationListItemContent,
  NavigationNavLink,
} from "./navigationItems";
import { t } from "i18next";
import Restricted from "../../consumer/restricted";

interface Props {
  drawerOpen: boolean;
  selectedIndex: number;
  handleListItemClick?: any;
  subdomain: string;
}

const ManagerRoutes: FC<Props> = ({
  drawerOpen,
  selectedIndex,
  handleListItemClick,
  subdomain,
}) => {
  if (subdomain === "manage") {
    return (
      <>
        <ListItem
          disablePadding
          className={drawerOpen ? "navigationItemOpen" : "navigationItem"}
        >
          <NavigationNavLink to={"/mitarbeiter"}>
            <NavigationItemButton
              drawerOpen={drawerOpen}
              onClick={(event: any) => handleListItemClick(event, 10)}
              selected={selectedIndex === 10}
            >
              <NavigationListItemContent
                drawerOpen={drawerOpen}
                primaryText="Mitarbeiter"
                icon="employee"
              />
            </NavigationItemButton>
          </NavigationNavLink>
        </ListItem>

        <ListItem
          disablePadding
          className={drawerOpen ? "navigationItemOpen" : "navigationItem"}
        >
          <NavigationNavLink to="appointmentrequestopen">
            <NavigationItemButton
              drawerOpen={drawerOpen}
              onClick={(event: any) => handleListItemClick(event, 11)}
              selected={selectedIndex === 11}
            >
              <NavigationListItemContent
                drawerOpen={drawerOpen}
                primaryText="Termine"
                icon="appointments"
              />
            </NavigationItemButton>
          </NavigationNavLink>
        </ListItem>
        <ListItem
          disablePadding
          className={drawerOpen ? "navigationItemOpen" : "navigationItem"}
        >
          <NavigationNavLink to="buildings">
            <NavigationItemButton
              drawerOpen={drawerOpen}
              onClick={(event: any) => handleListItemClick(event, 12)}
              selected={selectedIndex === 12}
            >
              <NavigationListItemContent
                drawerOpen={drawerOpen}
                primaryText={t("buildings")}
                icon="building"
              />
            </NavigationItemButton>
          </NavigationNavLink>
        </ListItem>
        <Restricted permissionRequired="frontend_view_worker_appointments">
          <ListItem
            disablePadding
            className={drawerOpen ? "navigationItemOpen" : "navigationItem"}
          >
            <NavigationNavLink to="workerAppointments">
              <NavigationItemButton
                drawerOpen={drawerOpen}
                onClick={(event: any) => handleListItemClick(event, 13)}
                selected={selectedIndex === 13}
              >
                <NavigationListItemContent
                  drawerOpen={drawerOpen}
                  primaryText={t("worker_appointments")}
                  icon="bookedAppointments"
                />
              </NavigationItemButton>
            </NavigationNavLink>
          </ListItem>
        </Restricted>
        <Restricted permissionRequired="frontend_view_preventive_care">
          <ListItem
            disablePadding
            className={drawerOpen ? "navigationItemOpen" : "navigationItem"}
          >
            <NavigationNavLink to="/preventiveCareList">
              <NavigationItemButton
                drawerOpen={drawerOpen}
                onClick={(event: any) => handleListItemClick(event, 14)}
                selected={selectedIndex === 14}
              >
                <NavigationListItemContent
                  drawerOpen={drawerOpen}
                  primaryText={t("preventive_care")}
                  icon="preventiveCare"
                />
              </NavigationItemButton>
            </NavigationNavLink>
          </ListItem>
        </Restricted>
      </>
    );
  } else return <></>;
};

export default ManagerRoutes;
