import { Backdrop, Grid, Tooltip } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import GeneralListItem from "../general/generaListItem";
import { ClipLoader } from "react-spinners";
import { t } from "i18next";
import dayjs from "dayjs";
import styles from "./timeSlotItem.module.css";

interface Props {
  slotData: any;
  handleToggleDelete: (slot: any, deleted: any, func: any) => void;
  disabledAll?: boolean;
}

const TimeSlotItem: FC<Props> = ({ slotData, handleToggleDelete }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    setIsLoading(false);
  }, [slotData]);

  const slotTaken = slotData.appointment !== null;
  const slotDeleted = slotData.deleted === true;
  const slotTime = dayjs(slotData.start_date).format("HH:mm");
  const toolTipTitle = slotDeleted
    ? t("timeslot_deleted")
    : slotTaken
      ? t("timeslot_taken")
      : t("timeslot_available");

  return (
    <React.Fragment key={slotData.id}>
      <Tooltip placement="top" title={toolTipTitle}>
        <Grid
          item
          onClick={() => {
            if (!slotTaken) {
              setIsLoading(true);
              handleToggleDelete(slotData, slotDeleted, () => {
                setIsLoading(false);
              });
            }
          }}
        >
          <GeneralListItem
            allowHover={!slotTaken && true}
            hideButton={true}
            wrapperClassName={`
              ${styles.generalListItemWrapper} 
              ${slotDeleted ? styles.generalListItemWrapper__slotDeleted : ""}
              ${!slotDeleted && slotTaken ? styles.generalListItemWrapper__slotTaken : ""}
              ${!slotDeleted && !slotTaken ? styles.generalListItemWrapper__slotFree : ""}
            `}
            contentClassName={`${slotDeleted ? styles.generalListItemContent__slotDeleted : ""}`}
          >
            {slotTime}
            {isLoading && (
              <Backdrop
                className={styles.backdrop}
                open={true}
              >
                <ClipLoader color="#8c1ec8" />
              </Backdrop>
            )}
          </GeneralListItem>
        </Grid>
      </Tooltip>
    </React.Fragment>
  );
};

export default TimeSlotItem;
