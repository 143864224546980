import AppointmentList from "../components/appointmentDashboard/appointmentList";
import { useNavigate, useParams } from "react-router-dom";
import Restricted from "../consumer/restricted";
import AppointmentTreeView from "../components/appointmentDashboard/appointmentTreeView";

export default function AppointmentDashboard() {
  const { appointmentId } = useParams() as {
    appointmentId: string;
  };

  const navigate = useNavigate();

  const renderContent = () => {
    if (appointmentId !== undefined && appointmentId !== "") {
      return <AppointmentTreeView appointmentIdProp={appointmentId} />;
    }

    return (
      <AppointmentList
        onClickHandler={(appointmentId: any) => {
          navigate("/appointmentDashboard/" + appointmentId);
        }}
      />
    );
  };

  return (
    <Restricted permissionRequired="frontend_view_appointment_dashboard">
      {renderContent()}
    </Restricted>
  );
}
