import { Grid } from "@mui/material";
import { FC, useEffect, useState } from "react";
import AppointmentTreeItemCompanyInformation from "./appointmentTreeItemCompanyInformation";
import AppointmentTreeItemPersonInformation from "./appointmentTreeItemPersonInformation";
import { useDispatch } from "react-redux";
import { setSubjectSearch } from "../state/subjectSearch/subjectSearchSlice";

interface Props {
  appointment: any;
}

const AppointmentTreeItemSubjectInformation: FC<Props> = ({ appointment }) => {
  const [subject, setSubject] = useState<any>();
  const [companySubject, setCompanySubject] = useState<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (appointment) {
      setSubject({
        value: appointment?.subject?.id,
        label: appointment?.subject?.name,
        companyId: appointment?.subject?.company?.id,
      });
      setCompanySubject({
        value: appointment?.subject?.company?.subject_id,
        label: appointment?.subject?.company?.name,
        companyId: appointment?.subject?.company?.id,
      });
    }
  }, [appointment]);

  const navigateToSubjectOverview = () => {
    dispatch(setSubjectSearch(subject));
  };

  const navigateToSubjectOverviewForCompany = () => {
    dispatch(setSubjectSearch(companySubject));
  };

  function renderSubjectInformation(appointment: any) {
    if (appointment.subject?.is_company) {
      return (
        <AppointmentTreeItemCompanyInformation
          appointment={appointment}
          handleNavigate={navigateToSubjectOverview}
        />
      );
    } else
      return (
        <AppointmentTreeItemPersonInformation
          appointment={appointment}
          handleNavigate={navigateToSubjectOverview}
          handleNavigateToCompany={navigateToSubjectOverviewForCompany}
        />
      );
  }
  return (
    <Grid container item>
      {renderSubjectInformation(appointment)}
    </Grid>
  );
};

export default AppointmentTreeItemSubjectInformation;
