import React, { FC } from "react";
import Restricted from "../../consumer/restricted";
import { Collapse, ListItem } from "@mui/material";
import {
  NavigationItemButton,
  NavigationListItemContent,
  NavigationNavLink,
} from "./navigationItems";
import "./navigationBar.css";
import styles from "./adminRoutes.module.css";

interface Route {
  path: string;
  name: string;
  icon: string;
  index: number;
  permission: string;
}

interface GenericNavigationCollapseProps {
  routesToRender: Route[];
  drawerOpen: boolean;
  selectedIndex: number;
  subRouteOpen: any;
  handleListSoubrouteItemClick: (event: any, subrouteIndex: number) => void;
  listItemLabel: string;
}

const GenericNavigationCollapse: FC<GenericNavigationCollapseProps> = ({
  routesToRender,
  drawerOpen,
  selectedIndex,
  subRouteOpen,
  handleListSoubrouteItemClick,
  listItemLabel,
}) => {
  return (
    <Collapse
      in={subRouteOpen[listItemLabel.toLowerCase()]}
      timeout="auto"
      unmountOnExit
      className={styles.collapse}
      component={"ul"}
    >
      {routesToRender.map((subroute: any) => (
        <Restricted
          key={subroute.name + "_restriction"}
          permissionRequired={subroute.permission}
        >
          <ListItem
            disablePadding
            className={drawerOpen ? "navigationItemOpen" : "navigationItem"}
            key={subroute.name}
          >
            <NavigationNavLink to={subroute.path}>
              <NavigationItemButton
                drawerOpen={drawerOpen}
                onClick={(event: any) =>
                  handleListSoubrouteItemClick(event, subroute.index)
                }
                selected={selectedIndex === subroute.index}
                pl={1}
              >
                <NavigationListItemContent
                  drawerOpen={drawerOpen}
                  primaryText={subroute.name}
                  icon={subroute.icon}
                />
              </NavigationItemButton>
            </NavigationNavLink>
          </ListItem>
        </Restricted>
      ))}
    </Collapse>
  );
};

export default GenericNavigationCollapse;
