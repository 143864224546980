import { FC } from "react";
import GenericCrudTable from "../tables/genericCrudTable";
import { t } from "i18next";

const TeamsTable: FC = () => {
  return (
    <GenericCrudTable
      allowColumnFiltering={true}
      showColumnFilterbyDefault={true}
      entity="team"
      heading={t("teams")}
      columns={[
        { header: "ID", accessorKey: "id", id: "id" },
        {
          header: t("name"),
          accessorKey: "name",
          id: "name",
          enableForCreate: true,
        },
        {
          header: t("description"),
          accessorKey: "description",
          id: "description",
          enableForCreate: true,
        },
      ]}
    />
  );
};

export default TeamsTable;
