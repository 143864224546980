import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { FC } from "react";
import { t } from "i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./appointmentTreeItemWidgets.module.css";

interface Props {
  widgets: any;
}

const AppointmentTreeItemWidgets: FC<Props> = ({ widgets }) => {
  const renderWidgets = () => {
    const sortedData = [...widgets].sort((a: any, b: any) => {
      if (a?.widget_priority !== b?.widget_priority) {
        return a?.widget_priority - b?.widget_priority;
      }
      return a?.widget_title.localeCompare(b?.widget_title);
    });

    return (
      <>
        {sortedData.map((widget: any, index: any) => (
          <Grid key={index} item xs={12}>
            <Accordion
              className={styles.accordion}
              defaultExpanded={index === 0}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <h2 className={styles.accordionTitle}>{widget.widget_title}</h2>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container component={List} className={styles.noPadding}>
                  {widget.items.map((item: any, itemIndex: any) => (
                    <Grid
                      item
                      component={ListItem}
                      key={itemIndex}
                      xs={3}
                      className={styles.noPadding}
                    >
                      <ListItemText
                        primary={item?.measurement_definition?.label}
                        primaryTypographyProps={{
                          className: styles.primaryText,
                        }}
                        secondaryTypographyProps={{
                          className: styles.secondaryText,
                        }}
                        secondary={
                          item.value
                            ? item.value === "yes"
                              ? t("yes")
                              : item.value === "no"
                              ? t("no")
                              : item.value
                            : "-"
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </>
    );
  };

  return <>{renderWidgets()}</>;
};

export default AppointmentTreeItemWidgets;
