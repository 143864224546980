import React, { useEffect, useState } from "react";
import { Fab } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import debounce from "lodash/debounce";
import styles from "./pageScrollTo.module.css";

interface PageScrollToProps {
    containerId?: string;
    threshold?: number;
    debounceDelay?: number;
    buttonStyles?: React.CSSProperties;
    className?: string;
    scrollDirection: "up" | "down" | "bidirectional";
    showButtonCondition?: boolean | (() => boolean);
    position?: "left" | "right" | "center";
    showAfterScroll?: number;
}

const PageScrollTo: React.FC<PageScrollToProps> = ({
    containerId = "rootOutletContent",
    threshold = 10,
    debounceDelay = 100,
    buttonStyles = {},
    className = "",
    scrollDirection,
    showButtonCondition = true,
    position = "right",
    showAfterScroll = 100,
}) => {
    if (scrollDirection === "bidirectional" && showAfterScroll !== undefined) {
        console.error(
            `Invalid configuration: 'showAfterScroll' cannot be used with 'scrollDirection="bidirectional"'\n` +
            `Remove 'showAfterScroll' or set 'scrollDirection' to 'up' or 'down'.`
        );
    }

    const [showScrollButton, setShowScrollButton] = useState(false);
    const [isPageAtTop, setIsPageAtTop] = useState(true);

    const scrollToTop = () => {
        const container = document.getElementById(containerId);
        container?.scrollTo({ top: 0, behavior: "smooth" });
    };

    const scrollToBottom = () => {
        const container = document.getElementById(containerId);
        container?.scrollTo({ top: container.scrollHeight, behavior: "smooth" });
    };

    useEffect(() => {
        const handleScroll = () => {
            const container = document.getElementById(containerId);
            if (container) {
                const scrollTop = container.scrollTop;
                const scrollHeight = container.scrollHeight - container.clientHeight;
                setIsPageAtTop(scrollTop <= threshold);

                if (scrollDirection === "up") {
                    setShowScrollButton(scrollTop > showAfterScroll);
                } else if (scrollDirection === "down") {
                    setShowScrollButton(scrollTop > showAfterScroll && scrollTop < scrollHeight - threshold);
                } else {
                    const shouldShowButton =
                        typeof showButtonCondition === "function"
                            ? showButtonCondition()
                            : showButtonCondition;
                    setShowScrollButton(shouldShowButton);
                }
            }
        };

        const debouncedHandleScroll = debounce(handleScroll, debounceDelay);
        const container = document.getElementById(containerId);

        if (container) {
            container.addEventListener("scroll", debouncedHandleScroll);
            handleScroll();
        }

        return () => {
            container?.removeEventListener("scroll", debouncedHandleScroll);
        };
    }, [containerId, threshold, debounceDelay, scrollDirection, showButtonCondition, showAfterScroll]);

    const handleButtonClick = () => {
        if (scrollDirection === "up" || (scrollDirection === "bidirectional" && !isPageAtTop)) {
            scrollToTop();
        } else if (scrollDirection === "down" || (scrollDirection === "bidirectional" && isPageAtTop)) {
            scrollToBottom();
        }
    };

    const shouldShowUpIcon = scrollDirection === "up" || (scrollDirection === "bidirectional" && !isPageAtTop);
    const shouldShowDownIcon = scrollDirection === "down" || (scrollDirection === "bidirectional" && isPageAtTop);

    return showScrollButton ? (
        <Fab
            color="secondary"
            size="small"
            onClick={handleButtonClick}
            className={`${styles.baseStyles} ${className}`}
            style={{
                ...buttonStyles,
                left: position === "left" ? "1rem" : position === "center" ? "50%" : undefined,
                right: position === "right" ? "1rem" : undefined,
                transform: position === "center" ? "translateX(-50%)" : undefined,
            }}
        >
            {shouldShowUpIcon ? <KeyboardArrowUpIcon /> : shouldShowDownIcon ? <KeyboardArrowDownIcon /> : null}
        </Fab>
    ) : null;
};

export default PageScrollTo;
