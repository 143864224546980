import { useEffect, useState } from "react";
import GenericCrudTable from "../../components/tables/genericCrudTable";
import { useNavigate } from "react-router-dom";
import { IExamination } from "../../types/Entities";
import { useTranslation } from "react-i18next";
import { api } from "../../helper/api";
import GenericErrorMessageModal from "../../components/forms/errorHandling/genericErrorMessageModal";
import React from "react";

export default function ProbeTable() {
  // eslint-disable-next-line
  const [examination, setExamination] = useState<IExamination[]>([]);
  const [error, setError] = useState<any>();

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    api.genericApiRequest({
      entity: "examination",
      method: "get",
      successHandler: (res: any) => {
        setExamination(res.data.results);
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  }, []);

  const replaceDate = (id: any) => {
    const date = new Date(id).toLocaleString();
    return date;
  };

  return (
    <>
      <GenericCrudTable
        heading={t("probe")}
        entity={"probe"}
        customEditHandler={(row: any) => {
          navigate(`/settings/probeEditor/${row.original.id}`);
        }}
        columns={[
          { header: "ID", accessorKey: "id", id: "id" },
          {
            header: t("name"),
            accessorKey: "name",
            id: "name",
            enableForCreate: true,
            required: true,
            errorMessage: t("field_required"),
          },
          {
            header: t("description"),
            accessorKey: "description",
            id: "description",
            enableForCreate: true,
            textarea: true,
          },
          {
            header: t("examination_set"),
            accessorKey: "examination_set",
            id: "examination_set",
            required: true,
            errorMessage: t("field_required"),
            accessorFn: (row: any) => {
              const examinations: any = [];
              if (row.examination_set.length !== 0) {
                for (const key in row.examination_set) {
                  const name = row.examination_set[key].name;
                  examinations.push(name);
                }
              }
              return (
                <>
                  {examinations
                    .sort((a: any, b: any) => a.localeCompare(b))
                    .map((name: any) => (
                      <React.Fragment key={name}>
                        <span>{name}</span>
                        <br />
                      </React.Fragment>
                    ))}
                </>
              );
            },

            // Cell: ({ cell }: any) => {
            //   return (
            //     <div>{replaceIdWithLabel(examination, cell.getValue())}</div>
            //   );
            // },
            enableForCreate: true,
            multiselect: true,
            entity: "examination",
          },
          {
            header: t("created_on"),
            accessorKey: "created_on",
            id: "created_on",
            sortingFn: "datetime",
            Cell: ({ cell }: any) => {
              return <span>{replaceDate(cell.getValue())}</span>;
            },
          },
          {
            header: t("created_by"),
            accessorKey: "created_by",
            customColumnFilterId: "created_by_name",

            id: "created_by",
            Cell: ({ cell }: any) => {
              return <span>{cell.getValue().name}</span>;
            },
          },
          {
            header: t("modified_on"),
            accessorKey: "modified_on",
            id: "modified_on",
            sortingFn: "datetime",
            Cell: ({ cell }: any) => {
              return <span>{replaceDate(cell.getValue())}</span>;
            },
          },
          {
            header: t("modified_by"),
            accessorKey: "modified_by",
            customColumnFilterId: "modified_by_name",

            id: "modified_by",
            Cell: ({ cell }: any) => {
              return <span>{cell.getValue().name}</span>;
            },
          },
        ]}
      />
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
}
