import { FC } from "react";
import PermissionTable from "../../components/permissions/permissionTable";
import Restricted from "../../consumer/restricted";

interface Props {
  title?: string;
}

const PermissionList: FC<Props> = () => {
  return (
    <Restricted permissionRequired="frontend_view_permission">
      <PermissionTable />
    </Restricted>
  );
};

export default PermissionList;
