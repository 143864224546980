import { useState } from "react";
import LoginForm from "../components/forms/loginForm";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Background from "../icons/background";
import { renderToStaticMarkup } from "react-dom/server";
import "../components/styles/login.css";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Modal } from "@mui/material";
import { t } from "i18next";
import styles from "./login.module.css";

interface Props {
  subdomain: string;
}

export default function Login(props: Props) {
  const [error, setError] = useState<string | null>(null);
  const theme = useTheme();

  const handleErrorMessage = (errorCode: string) => {
    setError(errorCode);
  };
  const handleResetErrorMessage = () => {
    setError(null);
  };
  const svgString = encodeURIComponent(renderToStaticMarkup(<Background />));

  const backgroundImage = {
    background: `url('data:image/svg+xml;utf8, ${svgString}')`,
  } as React.CSSProperties;

  const errorMessage = (
    <div className={styles.errorContainer}>
      <ErrorOutlineIcon />
      <div className={styles.errorMessageWrapper}>
        <span className={styles.errorMessageSpan}>{t("login_not_possible")}</span>
        <span className={styles.errorMessageSpan}>
          {t("check_password_and_try_again")}
        </span>
        <span className={styles.errorMessageSpan}>
          {t("for_questions_contact")} {""}
          <a className={styles.emailStyle} href="mailto:service@kaerhealth.com">
            {" "}
            service@kaerhealth.com
          </a>
        </span>
        {error && (
          <span className={styles.errorMessageSpan}>
            ({t("error")} {error})
          </span>
        )}
      </div>
    </div>
  );

  function portalName() {
    switch (props.subdomain) {
      case "admin":
        return t("kaer_staff_portal");
      case "manage":
        return t("kaer_manager_portal");
      case "portal":
        return t("kaer_employee_portal");
    }
  }

  const loginWrapper = {
    width: useMediaQuery(theme.breakpoints.up("sm")) ? "50%" : "80%",
    margin: useMediaQuery(theme.breakpoints.up("sm")) ? "0" : "2rem 0",
  } as React.CSSProperties;

  return (
    <div className={styles.mainContainer} style={backgroundImage} onClick={handleResetErrorMessage}>
      <div style={loginWrapper}>
        <LoginForm
          errorHandler={handleErrorMessage}
          errorReset={handleResetErrorMessage}
          subdomain={props.subdomain}
          portalName={portalName}
        />
      </div>
      <Modal className={styles.modalContainer} open={!!error}>
        {errorMessage}
      </Modal>
    </div>
  );
}
