import React, { FC, useEffect, useMemo, useState } from "react";
import Modal from "../modal";
import DropDown from "../forms/inputs/dropDown";
import DatePicker from "react-datepicker";
import { t } from "i18next";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";

interface PreventiveCareFilterModalProps {
  modalOpen: boolean;
  handleModalState: (state: boolean) => void;
  handleFilterValuesOnModalClose: (
    filter: any,
    updateFilter: any,
    dateRange: any,
    updateDateRange: any
  ) => void;
  handleDateChangeForApiCallItem: (dateRange: any) => void;
  handleDropDownChangeForApiCallItem: (filter: any) => void;
  presetValues: { filter: ""; dateRange: [] };
}

const PreventiveCareFilterModal: FC<PreventiveCareFilterModalProps> = ({
  modalOpen,
  handleModalState,
  handleFilterValuesOnModalClose,
  handleDateChangeForApiCallItem,
  handleDropDownChangeForApiCallItem,
  presetValues,
}) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [chosenFilter, setChosenFilter] = useState<string>(
    presetValues?.filter !== "" ? presetValues.filter : ""
  );
  registerLocale("de", de);

  const options = useMemo(() => {
    const filteringOptions = [
      { value: "last_executed_on", label: t("last_executed_on") },
      { value: "next_appointment_on", label: t("next_appointment_on") },
      { value: "valid_until", label: t("valid_until") },
    ];
    return filteringOptions;
  }, []);

  useEffect(() => {
    if (presetValues.filter !== "" && dateRange.length === 2) {
      //   setChosenFilter(presetValues.filter);
      setDateRange(presetValues.dateRange);
    }
  }, [presetValues]);

  const handleDropDownChange = (selectedOption: any) => {
    if (selectedOption && selectedOption.value !== undefined) {
      setChosenFilter(selectedOption.value);
    }
  };
  const handleDateChange = (update: any) => {
    setDateRange(update);
  };
  const modalContent = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <DropDown
          options={options}
          onChange={handleDropDownChange}
          isClearable={true}
          defaultValue={chosenFilter}
          resetInput={() => setChosenFilter("")}
        />
        <DatePicker
          selectsRange={true}
          onChange={(update) => {
            handleDateChange(update);
          }}
          locale={"de"}
          calendarStartDay={1}
          startDate={startDate}
          endDate={endDate}
          inline
          disabledKeyboardNavigation
        />
      </div>
    );
  };
  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        handleFilterValuesOnModalClose(
          chosenFilter,
          setChosenFilter,
          dateRange,
          setDateRange
        );
      }}
      title={"Filter"}
      hideSubmitButton={false}
      onSubmit={() => {
        handleDateChangeForApiCallItem(dateRange);
        handleDropDownChangeForApiCallItem(chosenFilter);
        handleModalState(false);
      }}
      buttonPlacement={"space-between"}
      cancelButtonText={t("cancel")}
      submitButtonText={t("apply_filter")}
      disableSubmit={
        !chosenFilter || dateRange[0] === null || dateRange[1] === null
      }
    >
      {modalContent()}
    </Modal>
  );
};

export default PreventiveCareFilterModal;
