import { FC } from "react";
import TeamsTable from "../../components/teams/teamsTable";
import Restricted from "../../consumer/restricted";

const TeamsList: FC = () => {
  return (
    <Restricted permissionRequired="frontend_view_team">
      <TeamsTable />
    </Restricted>
  );
};

export default TeamsList;
