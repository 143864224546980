import { ReactNode, useEffect, useState } from "react";
import {
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import GenericCrudTableModalWrapperField from "./genericCrudTableModalWrapperField";
import { useForm, FormProvider } from "react-hook-form";
import { t } from "i18next";
import style from "./genericCrudTableModal.module.css";

interface Props {
  columns?: any;
  title?: string;
  submitButtonText?: string;
  presetValues?: any;
  onSubmit?: (values: any, resetFunction?: () => void) => void;
  onClose?: () => void;
  companySubjectId?: string;
  companyId?: any;
  open: boolean;
  modalMode?: string;
  userEmails?: string[];
  additionalColumnsForCreate?: any;
  additionalColumnsForEdit?: any;
  subjectIdForBuildings?: any;
  additionalComponentForModal?: ReactNode;
  limit?: string;
}

export default function GenericCrudTableModal(props: Props) {
  const [values, setValues] = useState<any>(() =>
    props.columns.reduce((acc: any, column: any) => {
      if (column.enableForCreate) {
        acc[column.id ?? ""] = "";
      }

      return acc;
    }, {})
  );
  const [formColumns, setFormColumns] = useState<any>([]);
  let title = t("add_new_element");
  let submitButtonText = t("add");

  const methods = useForm<any>({
    mode: "onChange",
  });
  if (props.title) {
    title = props.title;
  }
  if (props.submitButtonText) {
    submitButtonText = props.submitButtonText;
  }

  useEffect(() => {
    const newFormColumns: any = [];
    const newValues: any = {};

    for (const columnKey in props.columns) {
      const column = props.columns[columnKey];
      if (column.enableForCreate) {
        newFormColumns.push(column);
      }
    }

    if (props.modalMode !== "edit") {
      for (const columnKey in props.additionalColumnsForCreate) {
        const column = props.additionalColumnsForCreate[columnKey];

        if (column.columnOnTop) {
          newFormColumns.unshift(column);
        } else {
          newFormColumns.push(column);
        }
      }
    }

    if (props.modalMode === "edit") {
      for (const columnKey in props.additionalColumnsForEdit) {
        const column = props.additionalColumnsForEdit[columnKey];
        newFormColumns.push(column);
      }
    }

    for (const key in newFormColumns) {
      const currentColumnName = newFormColumns[key].id;
      const currentRawValue = props.presetValues[currentColumnName];

      let currentValue = currentRawValue;

      if (typeof currentValue === "boolean") {
        currentValue = String(currentValue);
      }

      if (
        newFormColumns[key].entity &&
        typeof currentRawValue === "object" &&
        currentRawValue?.id
      ) {
        currentValue = currentRawValue.id;
      }

      newValues[currentColumnName] = currentValue;

      if (
        newValues["additional_data"] &&
        newValues["additional_data"] === undefined
      ) {
        newValues["additional_data"] = "{}";
      }
    }

    setValues(newValues);
    setFormColumns(newFormColumns);
  }, [props.presetValues, props.columns]);

  const handleFormSubmit = () => {
    props?.onSubmit?.(values, methods.reset);
  };

  const handleClose = () => {
    props.onClose?.();
    methods.clearErrors();
    methods.reset();
  };

  const handleFormInputOnChange = (columnName: any, value: any) => {
    setValues((values: any) => ({ ...values, [columnName]: value }));
  };

  const content = (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleFormSubmit)} noValidate>
        <Stack className={style.stackFormWrapper}>
          {formColumns.map((column: any) => (
            <GenericCrudTableModalWrapperField
              additionalComponentForModal={props.additionalComponentForModal}
              subjectIdForBuildings={props.subjectIdForBuildings}
              additionalUrlParameters={column.additionalUrlParameters}
              modalMode={props.modalMode}
              required={column.required}
              errorMessage={column.errorMessage}
              validate={column.validate}
              rule={column.rule}
              type={column.textfieldType}
              maxLength={column.maxLength}
              maxLengthMessage={column.maxLengthMessage}
              errorMessageValidation={column.errorMessageValidation}
              companySubjectId={props.companySubjectId}
              companyId={props.companyId}
              key={column.id + "_" + column.header}
              entity={column.entity}
              label={column.header}
              name={column.id}
              selectOptions={column.selectOptions}
              isJson={column.isJson}
              value={values[column.id]}
              multiselect={column.multiselect}
              textarea={column.textarea}
              checkBox={column.checkBox}
              datePicker={column.datePicker}
              dateTimePicker={column.dateTimePicker}
              defaultPickerDate={column.defaultDate}
              hideField={column.hideField}
              helperText={column.helperText}
              autoComplete={column.autoComplete}
              asyncEntity={column.asyncEntity}
              asyncDropDown={column.asyncDropDown}
              userEmails={props.userEmails}
              queryParameter={column.queryParameter}
              customLabel={column.customLabel}
              setCompanyId={column.setCompanyId}
              filterByCategory={column.filterByCategory}
              getOnlyActiveUsers={column.getOnlyActiveUsers}
              createAble={column.createAble}
              showAddressForBuildingSelect={column.showAddressForBuildingSelect}
              companyIdForFilteringAsync={column.companyIdForFilteringAsync}
              onChange={(value: any) => {
                if (!column.multiselect && typeof value === "object") {
                  value = String(value);
                }

                handleFormInputOnChange(column.id, value);
              }}
              limit={props.limit}
            />
          ))}
        </Stack>
        <DialogActions sx={{ p: "0.75rem", justifyContent: "space-between" }}>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button color="secondary" type="submit" variant="contained">
            {submitButtonText}
          </Button>
        </DialogActions>
      </form>
    </FormProvider>
  );

  return (
    <Dialog
      open={props.open}
      PaperProps={{ sx: { maxHeight: "95%" } }}
      className={style.dialogContainer}
      onClose={handleClose}
    >
      <DialogTitle textAlign="center">{title}</DialogTitle>
      <DialogContent sx={{ overflowY: "visible" }}>{content}</DialogContent>
    </Dialog>
  );
}
