import { FC, useEffect, useRef, useState } from "react";
import Restricted from "../../consumer/restricted";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
  Tooltip,
} from "@mui/material";
import { t } from "i18next";
import DropDownSubjectSearch from "../../components/forms/inputs/dropDownSubjectSearch";
import SubjectOverviewCard from "../../components/subjectOverview/subjectOverviewCard";
import { api } from "../../helper/api";
import GenericErrorMessageModal from "../../components/forms/errorHandling/genericErrorMessageModal";
import SubjectOverviewDocuments from "../../components/subjectOverview/subjectOverviewDocuments";
import SubjectOverviewAppointments from "../../components/subjectOverview/subjectOverviewAppointments";
import SubjectOverviewNotes from "../../components/subjectOverview/subjectOverviewNotes";
import SubjectOverviewAppointmentRequests from "../../components/subjectOverview/subjectOverviewAppointmentRequests";
import SubjectOverviewTodos from "../../components/subjectOverview/subjectOverviewTodos";
// import SubjectOverviewStatus from "../../components/subjectOverview/subjectOverviewStatus";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "../../components/forms/inputs/button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../components/state/store";
import styles from "./subjectCompanyOverview.module.css";
import AppointmentTreeItemUploadButton from "../../components/appointmentDashboard/appointmentTreeItemUploadButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

interface Props {
  subdomain?: string;
}

const SubjectCompanyOverview: FC<Props> = ({ subdomain }) => {
  //eslint-disable-next-line
  const [subjectOptions, setSubjectOptions] = useState<any>([]);
  const [selectedSubjectId, setSelectedSubjectId] = useState<any>("");
  const [detailedSubject, setDetailedSubject] = useState<any>({});
  const [error, setError] = useState<any>();
  // const [coreDataIdForStatus, setCoreDataIdForStatus] = useState<string>("");
  const [formValuesForOverview, setFormValuesForOverview] = useState<any>([]);
  // const [formValuesForStatus, setFormValuesForStatus] = useState<any>([]);
  const [expandedAppointmentRequests, setExpandedAppointmentRequests] =
    useState<boolean>(false);
  const [expandedDocuments, setExpandedDocuments] = useState<boolean>(false);
  const [expandedTodos, setExpandedTodos] = useState<boolean>(false);
  const [expandedNotes, setExpandedNotes] = useState<boolean>(true);
  const [expandedAppointments, setExpandedAppointments] =
    useState<boolean>(true);

  const overviewSubjectSearchRef = useRef(null);

  const selectedSubject = useSelector(
    (state: RootState) => state.subjectSearch
  );
  const navigate = useNavigate();

  const handleSubjectSelect = (selectedOption: any) => {
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.value
    ) {
      setSelectedSubjectId(selectedOption.value);
      setDetailedSubject({});
    }
  };
  const getSubjectOverview = () => {
    const additionalRouteParts: any = {};
    additionalRouteParts["overview"] = "/";
    api.genericApiRequest({
      method: "get",
      entity: "subject",
      entityId: selectedSubjectId,
      additionalRouteParts: additionalRouteParts,
      successHandler: (res: any) => {
        const data = res.data;
        setFormValuesForOverview(data.overview);
        // setFormValuesForStatus(data.customer_status);
        // setCoreDataIdForStatus(data.customer_status?.coredata_id);
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  };

  useEffect(() => {
    if (selectedSubjectId !== "") {
      api.genericApiRequest({
        method: "get",
        entity: "subject",
        entityId: selectedSubjectId,
        successHandler: (res: any) => {
          setDetailedSubject(res.data);
          if (res.data.is_company === true) {
            getSubjectOverview();
          }
        },
        failHandler: (error: any) => {
          setError(error);
        },
      });
    }
  }, [selectedSubjectId]);

  const resetSubject = () => {
    setDetailedSubject({});
    setSelectedSubjectId("");
  };

  const handleExpandClick = (item: any) => {
    if (item === "appointmentRequest") {
      setExpandedAppointmentRequests(!expandedAppointmentRequests);
    }
    if (item === "documents") {
      setExpandedDocuments(!expandedDocuments);
    }
    if (item === "todos") {
      setExpandedTodos(!expandedTodos);
    }
    if (item === "notes") {
      setExpandedNotes(!expandedNotes);
    }
    if (item === "appointments") {
      setExpandedAppointments(!expandedAppointments);
    }
  };

  // **OnChangeFunction for Status which is currently not used. Comment back in if this changes in the future**

  // const handleSubmitOnChange = (data: any, id: any, name: any, unit: any) => {
  //   const routeParts: any = {};
  //   routeParts["submit_metrics"] = "/";
  //   let result;
  //   if (data && data !== undefined) {
  //     if (Array.isArray(data)) {
  //       const multiValue = [];
  //       for (const key in data) {
  //         const value = data[key].value;
  //         multiValue.push(value);
  //       }

  //       result = {
  //         coredata_id: coreDataIdForStatus,
  //         metrics: [
  //           {
  //             unit: unit,
  //             name: name,
  //             measurement_definition: id,
  //             value: multiValue,
  //           },
  //         ],
  //       };
  //     } else if (data.value && data.value !== undefined) {
  //       result = {
  //         coredata_id: coreDataIdForStatus,
  //         metrics: [
  //           {
  //             unit: unit,
  //             name: name,
  //             measurement_definition: id,
  //             value: data?.value,
  //           },
  //         ],
  //       };
  //     }

  //     api.genericApiRequest({
  //       entity: "metric",
  //       method: "put",
  //       additionalRouteParts: routeParts,
  //       successHandler: (res: any) => {
  //         const responseData = res.data[0];
  //         const updatedFormValues = formValuesForStatus.map((item: any) => {
  //           if (item.name === responseData.name) {
  //             return { ...item, value: responseData.value };
  //           }
  //           return item;
  //         });
  //         setFormValuesForStatus(updatedFormValues);
  //       },
  //       failHandler: (error: any) => {
  //         setError(error);
  //       },
  //       submitData: result,
  //     });
  //   }
  // };

  const renderServiceLevelChip = () => {
    if (detailedSubject && detailedSubject.is_company) {
      const serviceLevel = `service_level_${detailedSubject.company?.service_level}`;
      return (
        <Tooltip title={t("service_level")} placement="top">
          <Chip label={t(serviceLevel)} className={styles.serviceLevelChip} />
        </Tooltip>
      );
    }
  };

  const renderCustomerButtons = () => {
    return (
      <Grid item xs={2}>
        <Button
          title={t("customer")}
          variant="contained"
          color="secondary"
          onClick={() => navigate("/admin/userAdministration")}
          disabled={
            (!selectedSubject.value &&
              (selectedSubject.value === "" ||
                selectedSubject.value === undefined)) ||
            !detailedSubject.is_company
          }
        />
      </Grid>
    );
  };
  const renderCustomSubjectButtons = () => {
    const isDetailedSubjectValid =
      detailedSubject &&
      typeof detailedSubject.is_company === "boolean" &&
      typeof detailedSubject.is_registered === "boolean" &&
      typeof detailedSubject.is_analog === "boolean";

    if (!isDetailedSubjectValid) {
      return null;
    }

    if (
      !detailedSubject.is_company &&
      (!detailedSubject.is_registered || detailedSubject.is_analog)
    ) {
      return (
        <Grid item xs={9}>
          <AppointmentTreeItemUploadButton
            wrapperStyle={{
              display: "flex",
              gap: "0.25rem",
            }}
            subject={detailedSubject}
            useCase="formOverview"
            margin="0"
          />
        </Grid>
      );
    }

    return null;
  };

  return (
    <Restricted permissionRequired="frontend_view_subject_company_overview">
      {/*MainContainer _________________________________________________________________________________________________ */}
      <Grid container className={styles.mainContainer}>
        <Grid item xs={12}>
          <h1 className={styles.title}>{t("subject_company_overview")}</h1>
        </Grid>
        <Grid item container xs={12} columnSpacing={"1rem"}>
          <Grid item xs={7}>
            <DropDownSubjectSearch
              options={subjectOptions}
              onChange={handleSubjectSelect}
              subdomain={subdomain}
              useGlobalState={true}
              isClearable={true}
              resetInput={resetSubject}
              customRef={overviewSubjectSearchRef}
              autofocus={true}
            />
          </Grid>
          {selectedSubjectId !== "" && (
            <Grid
              container
              item
              xs={5}
              className={styles.buttonContainer}
              columnSpacing={"0.25rem"}
            >
              {renderCustomerButtons()}
              {renderCustomSubjectButtons()}
              {renderServiceLevelChip()}
            </Grid>
          )}
        </Grid>
        {Object.keys(detailedSubject).length !== 0 && (
          <>
            {/* Container 1_________________________________________________________________________________________________ */}
            {detailedSubject.is_company && (
              <Grid
                container
                columnSpacing={"1rem"}
                className={styles.container1}
              >
                <Grid item xs={12} className={styles.fullHeight}>
                  <SubjectOverviewCard
                    formValues={formValuesForOverview}
                    subjectName={detailedSubject.name}
                  />
                </Grid>

                {/* ** SubjectOverViewStatus disabled for now because its not activly used at the momement. Can be commented back in if this changes. 
               Has to set xs={7} for the  SubjectOverviewCard.
               Also commented out all relevant States and Calls for now ** */}

                {/* <Grid item xs={5} className={styles.fullHeight}>
                  <SubjectOverviewStatus
                    handleSubmitOnChange={handleSubmitOnChange}
                    formValues={formValuesForStatus}
                  />
                </Grid> */}
              </Grid>
            )}
            {/* _________________________________________________________________________________________________ */}
            {/*Container 2 _________________________________________________________________________________________________ */}
            <Grid container columnSpacing={"1rem"}>
              <Grid item xs={7}>
                <Accordion
                  defaultExpanded={true}
                  className={
                    expandedNotes ? "MuiPaperRootCollapse" : "borderRadius"
                  }
                  expanded={expandedNotes}
                  onChange={() => handleExpandClick("notes")}
                  square={true}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h5 className="no-margin">{t("notes")}</h5>{" "}
                  </AccordionSummary>
                  <AccordionDetails>
                    <SubjectOverviewNotes detailedSubject={detailedSubject} />
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={5}>
                <Accordion
                  square={true}
                  defaultExpanded={true}
                  className={
                    expandedAppointments
                      ? "MuiPaperRootCollapse"
                      : "borderRadius"
                  }
                  expanded={expandedAppointments}
                  onChange={() => handleExpandClick("appointments")}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h5 className="no-margin">{t("appointment_multi")}</h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <SubjectOverviewAppointments
                      detailedSubject={detailedSubject}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
            {/* _________________________________________________________________________________________________ */}
            {/* Container 3_________________________________________________________________________________________________ */}
            <Grid container>
              <Grid item xs={12}>
                <Accordion
                  square={true}
                  className="MuiPaperRootCollapse"
                  expanded={expandedAppointmentRequests}
                  onChange={() => {
                    handleExpandClick("appointmentRequest");
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h5 className="no-margin">
                      {t("appointmentrequest_multi")}
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <SubjectOverviewAppointmentRequests
                      detailedSubject={detailedSubject}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
            {/* _________________________________________________________________________________________________ */}
            {/* Container 4_________________________________________________________________________________________________ */}
            <Grid container columnSpacing={"1rem"}>
              <Grid item xs={7}>
                <Accordion
                  square={true}
                  className={
                    expandedDocuments ? "MuiPaperRootCollapse" : "borderRadius"
                  }
                  expanded={expandedDocuments}
                  onChange={() => {
                    handleExpandClick("documents");
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h5 className="no-margin">{t("documents")}</h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <SubjectOverviewDocuments
                      subjectId={detailedSubject.id}
                      subjectForDispatch={detailedSubject}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item xs={5}>
                <Accordion
                  square={true}
                  className={
                    expandedTodos ? "MuiPaperRootCollapse" : "borderRadius"
                  }
                  expanded={expandedTodos}
                  onChange={() => {
                    handleExpandClick("todos");
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h5 className="no-margin">{t("todo_multi")}</h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <SubjectOverviewTodos detailedSubject={detailedSubject} />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
            {/* _________________________________________________________________________________________________ */}
          </>
        )}
      </Grid>
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </Restricted>
  );
};

export default SubjectCompanyOverview;
