import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import styles from "./defaultInput.module.css";

interface Props {
  mandatory?: boolean;
  label?: string;
  name?: string;
  infoTooltip?: boolean;
  infoTooltipMessage?: string | any;
  required?: boolean;
  onChange?: (event: any) => void;
  defaultValue?: string;
  autoComplete?: string;
  type?: string;
  placeholder?: string;
  readonly?: boolean;
  value?: string;
  onFocus?: () => void;
  onBlur?: (event?: any) => void;
  width?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  inputClassName?: string;
  labelClassName?: string;
  accept?: any;
  children?: any;
  inputStyle?: any;
  labelStyle?: any;
}
export default function DefaultInput(props: Props) {
  const defaultInputStyle = {
    display: "flex",
    flexDirection: "column",
    width: props.width ? props.width : "",
    ...props.style,
  } as React.CSSProperties;

  const textStyle = {
    border: "1px solid #bcbcbc",
    outline: "none",
    padding: "0.75rem",
    borderRadius: "0.5rem",
    ...props.inputStyle,
  } as React.CSSProperties;

  return (
    <label
      className={props.labelClassName ? props.labelClassName : ""}
      style={props.labelStyle ? props.labelStyle : defaultInputStyle}
    >
      <span>
        {props.mandatory === true ? `${props.label + "\u{002A}"}` : props.label}
        {props.infoTooltip && (
          <Tooltip title={props.infoTooltipMessage} placement="right">
            <InfoIcon className={styles.infoIcon} />
          </Tooltip>
        )}
      </span>
      <input
        accept={props.accept}
        className={props.inputClassName}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        style={textStyle}
        id={props.name}
        required={props.required}
        onChange={props.onChange}
        value={props.value}
        defaultValue={props.defaultValue}
        name={props.name}
        autoComplete={props.autoComplete}
        type={props.type}
        placeholder={props.placeholder}
        readOnly={props.readonly}
        disabled={props.disabled}
      />
      {props.children}
    </label>
  );
}
