import { CircleLoader } from "react-spinners";
import { Backdrop } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../components/state/store";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { navigateTo } from "../components/state/navigation/navigateToSlice";
import { Outlet, useNavigate } from "react-router-dom";
import { t } from "i18next";
import HeaderBarWrapper from "../components/navigation/headerBarWrapper";
import GenericNotification from "../components/notification/genericNotification";
import styles from "./root.module.css";

interface Props {
  subdomain: string;
}

export default function Root(props: Props) {
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();
  const navigateToState = useSelector((state: RootState) => state.navigateTo);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
    setNotificationMessage("");
  };

  useEffect(() => {
    if (navigateToState.navigate) {
      setTimeout(() => {
        {
          dispatch(navigateTo({ navigate: false, target: "" }));
          setNotificationMessage(t("branch_successfully_switched"));
          setNotificationVariant("success");
          setShowNotification(true);
        }
      }, 3000);
      navigate("/");
    }
  }, [navigateToState.navigate]);

  return (
    <main className="mainContainer">
      <HeaderBarWrapper subdomain={props.subdomain} />
      <div className="outletContent_Wrapper">
        <div id="rootOutletContent" className={styles.outletContent}>
          <Outlet />
        </div>
      </div>
      {navigateToState.navigate && (
        <Backdrop className={styles.backdrop} open={true}>
          <div className={styles.backdropContent}>
            <CircleLoader color="#ff6428" size={150} />
            <h1>{t("switch_branch")}</h1>
          </div>
        </Backdrop>
      )}
      {showNotification && (
        <GenericNotification
          message={notificationMessage}
          variant={notificationVariant}
          handleCloseSnackbar={resetStateOnCloseNotification}
        />
      )}
    </main>
  );
}
