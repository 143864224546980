import { FC } from "react";
import DefaultInput from "../forms/inputs/defaultInput";
import Button from "../forms/inputs/button";
import styles from "./measurementDefinitionSelect.module.css";
import { Button as MuiButton } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { t } from "i18next";

interface Props {
  id?: string | undefined;
  onChange: (id: string | undefined, value: string, label: string) => void;

  onDelete?: (id: string | undefined) => void;
  index: any;
  selectedInputCount: any;
  moveUp: any;
  moveDown?: any;
  label: any;
  value: any;
}

const MeasurementDefinitionSelect: FC<Props> = (props: Props) => {
  const handleValueChange = (event: any) => {
    props.onChange(props.id, event.target.value, props.label);
  };

  const handleLabelChange = (event: any) => {
    props.onChange(props.id, props.value, event.target.value);
  };

  return (
    <div className={styles.inputWrapper} id={props.id}>
      <DefaultInput
        label={t("label_alternative_1")}
        name={props.id}
        onChange={handleLabelChange}
        value={props.label}
      />
      <DefaultInput
        label={t("value")}
        name={props.id}
        onChange={handleValueChange}
        value={props.value}
      />
      <div>
        <Button
          title={t("delete")}
          onClick={() => props?.onDelete?.(props.id)}
        />
        <MuiButton
          type="button"
          variant="text"
          sx={{ color: "#8c1ec8", padding: "0", minWidth: "2rem" }}
          onClick={() => props.moveUp(props.index)}
          disabled={props.index === 0}
        >
          <ArrowDropUpIcon />
        </MuiButton>
        <MuiButton
          type="button"
          variant="text"
          sx={{ color: "#8c1ec8", padding: "0", minWidth: "2rem" }}
          onClick={() => props.moveDown(props.index)}
          disabled={props.index === props.selectedInputCount.length - 1}
        >
          <ArrowDropDownIcon />
        </MuiButton>
      </div>
    </div>
  );
};

export default MeasurementDefinitionSelect;
