import { FC } from "react";
import ForgottenPasswordForm from "../components/forms/forgottenPasswordForm";

const ForgottenPassword: FC = () => {
  return (
    <>
      <ForgottenPasswordForm />
    </>
  );
};

export default ForgottenPassword;
