import { FC } from "react";
import CreateMeasurementDefinition from "../../components/measurementDefinition/createMeasurementDefinition";
import Restricted from "../../consumer/restricted";

const MeasurementDefinitionCreate: FC = () => {
  return (
    <Restricted permissionRequired="frontend_view_measurement_definition">
      <CreateMeasurementDefinition />
    </Restricted>
  );
};

export default MeasurementDefinitionCreate;
