import { useNavigate } from "react-router-dom";
import GenericCrudTable from "../../components/tables/genericCrudTable";
import { useTranslation } from "react-i18next";
import Restricted from "../../consumer/restricted";

export default function MeasurementDefinitionList() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const replaceDate = (id: any) => {
    const date = new Date(id).toLocaleString();
    return date;
  };

  const isUsedInMeasurement = (row: any) => {
    if (row.original.is_used_in_measurement) {
      return row.original.is_used_in_measurement;
    }
  };

  return (
    <Restricted permissionRequired="frontend_view_measurement_definition">
      <GenericCrudTable
        allowColumnFiltering={true}
        showColumnFilterbyDefault={true}
        heading={t("measurementDefinitions")}
        entity={"measurementDefinition"}
        customEditHandler={(row: any) => {
          navigate(`/settings/createMeasurementDefinition/${row.original.id}`);
        }}
        customCreateHandler={() => {
          navigate(`/settings/createMeasurementDefinition/`);
        }}
        isUsedInMeasurement={isUsedInMeasurement}
        additionalUrlParameter={{ test_for_measurement_usage: true }}
        columns={[
          { header: "ID", accessorKey: "id", id: "id" },
          {
            header: t("name"),
            accessorKey: "name",
            id: "name",
            enableForCreate: true,
          },
          {
            header: t("label"),
            accessorKey: "label",
            id: "label",
            enableForCreate: true,
          },
          {
            header: t("short_label"),
            accessorKey: "short_label",
            id: "short_label",
            enableForCreate: true,
          },
          {
            header: t("type"),
            accessorKey: "type",
            id: "type",
            filterVariant: "select",
            filterSelectOptions: [
              { value: "bool", label: t("bool") },
              { value: "boolNoStatement", label: t("boolNoStatement") },

              { value: "int", label: t("int") },
              {
                value: "float",
                label: t("float"),
              },
              {
                value: "choice",
                label: t("choice"),
              },
              { value: "multiselect", label: t("multiselect") },
              { value: "string", label: t("string") },
              { value: "text", label: t("text") },
              { value: "date", label: t("date") },
            ],
            enableForCreate: true,
            accessorFn: (row: any) => {
              const mapping: any = {
                bool: `${t("bool")}`,
                boolNoStatement: `${t("boolNoStatement")}`,
                multiselect: `${t("multiselect")}`,
                int: `${t("int")}`,
                float: `${t("float")}`,
                choice: `${t("choice")}`,
                string: `${t("string")}`,
                text: `${t("text")}`,
                date: `${t("date")}`,
              };

              if (mapping[row.type]) {
                return mapping[row.type];
              }

              return row.type;
            },
            selectOptions: [
              { value: "bool", label: t("bool"), id: "bool" },
              { value: "int", label: t("int"), id: "int" },
              {
                value: "float",

                label: "Kommazahl",
                id: "float",
              },
              {
                value: "choice",

                label: "Auswahl",
                id: "choice",
              },
              {
                value: "multiselect",
                label: t("multiselect"),
                id: "multiselect",
              },

              { value: "string", label: t("string"), id: "string" },
              { value: "text", label: t("text"), id: "text" },
              { value: "date", label: t("date"), id: "date" },
            ],
          },
          {
            header: t("unit"),
            accessorKey: "unit",
            id: "unit",
            enableForCreate: true,
            filterVariant: "select",
            filterSelectOptions: [
              { value: "", label: "-", id: "0" },
              { value: "cm", label: "Centimeter", id: "cm" },
              { value: "Kg", label: "Kilogramm", id: "Kg" },
              { value: "g", label: "Gramm", id: "g" },
              { value: "°C", label: "Grad Celsius", id: "c" },
              { value: "%", label: "Prozent", id: "percent" },
            ],
            selectOptions: [
              { value: "", label: t("none"), id: "0" },
              { value: "cm", label: t("centimeter"), id: "cm" },
              { value: "Kg", label: t("kilogram"), id: "Kg" },
              { value: "g", label: t("gram"), id: "g" },
              { value: "°C", label: t("degree_celcius"), id: "c" },
              { value: "%", label: t("percent"), id: "percent" },
            ],
          },
          {
            header: t("reuse"),
            accessorKey: "reuse",
            id: "reuse",
            filterVariant: "select",
            filterSelectOptions: [
              {
                value: "false",
                label: "Nein",
                id: "false",
              },
              {
                value: "true",
                label: "Ja",
                id: "true",
              },
            ],
            enableForCreate: true,
            accessorFn: (row: any) => {
              if (row.reuse === true) {
                return t("yes");
              }

              return t("no");
            },
            selectOptions: [
              {
                value: "false",
                label: t("no"),
                id: "false",
              },
              {
                value: "true",
                label: t("yes"),
                id: "true",
              },
            ],
          },
          {
            header: t("observation"),
            accessorKey: "observation",
            id: "observation",
            enableForCreate: true,
          },
          {
            header: t("assessment"),
            accessorKey: "assessment",
            id: "assessment",
            enableForCreate: true,
          },
          {
            header: t("dangerous_answer"),
            accessorKey: "dangerous_answer",
            id: "dangerous_answer",
            filterVariant: "select",
            filterSelectOptions: [
              { label: t("yes"), value: "yes" },
              { label: t("no"), value: "no" },
            ],
            enableForCreate: true,
            accessorFn: (row: any) => {
              if (row.dangerous_answer === "yes") {
                return "Ja";
              }

              return "Nein";
            },
          },
          {
            header: t("possible_remedy"),
            accessorKey: "possible_remedy",
            id: "possible_remedy",
            enableForCreate: true,
          },
          {
            header: t("danger_potential"),
            accessorKey: "danger_potential",
            id: "danger_potential",
            filterVariant: "select",
            filterSelectOptions: [
              { label: t("low"), value: "low" },
              { label: t("mid"), value: "mid" },
              { label: t("high"), value: "high" },
            ],
            enableForCreate: true,
            accessorFn: (row: any) => {
              if (row.danger_potential === "low") {
                return t("low");
              } else if (row.danger_potential === "mid") {
                return t("mid");
              } else if (row.danger_potential === "high") {
                return t("high");
              } else return "";
            },
          },
          {
            header: t("created_on"),
            accessorKey: "created_on",
            id: "created_on",
            sortingFn: "datetime",
            filterVariant: "date",
            Cell: ({ cell }: any) => {
              return <span>{replaceDate(cell.getValue())}</span>;
            },
          },
          {
            header: t("created_by"),
            accessorKey: "created_by",
            customColumnFilterId: "created_by_name",

            id: "created_by",
            Cell: ({ cell }: any) => {
              return <span>{cell.getValue().name}</span>;
            },
          },
          {
            header: t("modified_on"),
            accessorKey: "modified_on",
            id: "modified_on",
            filterVariant: "date",

            sortingFn: "datetime",
            Cell: ({ cell }: any) => {
              return <span>{replaceDate(cell.getValue())}</span>;
            },
          },
          {
            header: t("modified_by"),
            accessorKey: "modified_by",
            customColumnFilterId: "modified_by_name",

            id: "modified_by",
            Cell: ({ cell }: any) => {
              return <span>{cell.getValue().name}</span>;
            },
          },
        ]}
      />
    </Restricted>
  );
}
