import { FC } from "react";
import GenericCrudTable from "../tables/genericCrudTable";
import { t } from "i18next";

interface Props {
  title?: string;
}

const PermissionTable: FC<Props> = () => {
  return (
    <GenericCrudTable
      allowColumnFiltering={true}
      showColumnFilterbyDefault={true}
      heading={t("permissions")}
      entity="permission"
      disallowDelete={true}
      columns={[
        { header: "ID", accessorKey: "id", id: "id" },
        {
          header: t("name"),
          accessorKey: "name",
          id: "name",
          enableForCreate: true,
        },

        { header: t("codename"), accessorKey: "codename", id: "codename" },
        {
          header: t("type"),
          accessorKey: "content_type",
          id: "content_type",
          size: 40,
        },
      ]}
    />
  );
};

export default PermissionTable;
