import { FC } from "react";
import { AppointmentRequestTable } from "../components/appointmentRequest/appointmentRequestTable";
import Restricted from "../consumer/restricted";

const AppointmentRequestTableView: FC = () => {
  return (
    <Restricted permissionRequired="frontend_view_appointment_request">
      <AppointmentRequestTable />
    </Restricted>
  );
};

export default AppointmentRequestTableView;
