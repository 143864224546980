import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useSignOut } from "react-auth-kit";
import { useDispatch } from "react-redux";
import styles from "./genericErrorMessageModal.module.css";
import { t } from "i18next";

interface Props {
  title: string;
  confirmButtonText?: string;
  error: any;
  onClosehandler?: () => void;
}

const GenericErrorMessageModal: FC<Props> = ({
  title,
  confirmButtonText,
  error,
  onClosehandler,
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(true);
  const signOut = useSignOut();
  const dispatch = useDispatch();

  const combinedSignout = () => {
    dispatch({ type: "clear" });
    signOut();
  };
  useEffect(() => {
    if (error) {
      setModalOpen(true);
    }
  }, [error]);

  let buttonText = "Ok";
  if (confirmButtonText) {
    buttonText = confirmButtonText;
  }

  let message: any = <List></List>;
  if (error === "logout") {
    combinedSignout();
  }

  if (typeof error === "string") {
    message = (
      <ListItem>
        <ListItemAvatar>
          <ErrorOutlineIcon className={styles.errorIcon} />
        </ListItemAvatar>
        <ListItemText primary={error} />
      </ListItem>
    );
  } else if (Array.isArray(error)) {
    message = error.map((errorItem, index) => (
      <ListItem key={index}>
        <ListItemAvatar>
          <ErrorOutlineIcon className={styles.errorIcon} />
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ className: styles.listItemTextPrimary }}
          primary={errorItem}
        />
      </ListItem>
    ));
  } else if (typeof error === "object") {
    message = Object.entries(error).flatMap(([fieldName, fieldValue]: any) => {
      if (
        Array.isArray(fieldValue) &&
        fieldValue.every((item) => typeof item === "object")
      ) {
        return fieldValue.map((nestedError: any, index: any) => (
          <ListItem key={`${fieldName}-${index}`}>
            <ListItemAvatar>
              <ErrorOutlineIcon className={styles.errorIcon} />
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ className: styles.listItemTextPrimary }}
              primary={nestedError.message || t("error_occurred")}
              secondary={
                <>
                  {Object.entries(nestedError).map(([key, value]: any) => {
                    const keysNotToRender = ["id"];
                    if (keysNotToRender.includes(key)) return null;
                    return (
                      <span style={{ display: "block" }} key={key}>
                        {key}: {Array.isArray(value) ? value.join(", ") : value}
                      </span>
                    );
                  })}
                </>
              }
            />
          </ListItem>
        ));
      } else {
        return (
          <ListItem key={fieldName}>
            <ListItemAvatar>
              <ErrorOutlineIcon className={styles.errorIcon} />
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ className: styles.listItemTextPrimary }}
              primary={fieldValue}
              secondary={fieldName}
            />
          </ListItem>
        );
      }
    });
  }

  return (
    <Dialog open={modalOpen} scroll="paper">
      <DialogTitle className={styles.dialogTitle}>{title}</DialogTitle>
      <DialogContent dividers>{message}</DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setModalOpen(false);
            onClosehandler?.();
          }}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenericErrorMessageModal;
