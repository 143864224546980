import { Box, Button } from "@mui/material";
import { t } from "i18next";
import { FC, useMemo } from "react";
import GenericCrudTable from "../tables/genericCrudTable";
import { MRT_ColumnDef } from "material-react-table";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import styles from "./subjectOverviewAppointments.module.css";
import { getStatusLabel } from "../../helper/statusOptions";

interface Props {
  detailedSubject?: any;
}

const SubjectOverviewAppointments: FC<Props> = ({ detailedSubject }) => {
  const replaceDate = (id: any) => {
    const date = new Date(id).toLocaleString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
    return date;
  };

  const customTopToolbarAction = (
    <Button color="secondary" variant="contained" startIcon={<OpenInNewIcon />}>
      <Link
        to="/appointmentList"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link_white}
      >
        {t("appointment_multi")}
      </Link>
    </Button>
  );
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        id: "id",
        enableHiding: false,
      },
      {
        header: t("datetime"),
        accessorKey: "datetime",
        id: "datetime",
        sortingFn: "datetime",
        maxSize: 160,
        accessorFn: (row: any) => {
          return (
            <Box className={styles.boxContainer}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link_purple}
                to={`/settings/appointmentEditor/${row.id}`}
              >
                {replaceDate(row.datetime)}
              </Link>
            </Box>
          );
        },
      },
      {
        header: t("name"),
        accessorKey: "categoryexamination_set",
        maxSize: 840,
        id: "categoryexamination_set",
        accessorFn: (row: any) => {
          const examinations: any = [];
          if (row.categoryexamination_set) {
            for (const key in row.categoryexamination_set) {
              if (row.categoryexamination_set[key].examination_name) {
                examinations.push(
                  row.categoryexamination_set[key].examination_name
                );
              }
            }
          }
          return examinations.join(", ");
        },
      },
      {
        header: t("status"),
        accessorKey: "status",
        id: "status",
        accessorFn: (row: any) => {
          const appointmentStatus = getStatusLabel(row?.status);
          return <span>{appointmentStatus}</span>;
        },
      },
      {
        header: t("executed_by"),
        accessorKey: "executed_by",
        id: "executed_by",
        enableForCreate: true,
        entity: "user",
        accessorFn: (row: any) => {
          if (row.executed_by && row.executed_by !== null) {
            return row.executed_by.name;
          } else return "-";
        },
      },
    ],
    []
  );
  return (
    <GenericCrudTable
      entity="appointment"
      columns={columns}
      customTopToolBarAction={customTopToolbarAction}
      customGlobalFilterPosition={"left"}
      customDensity="compact"
      customPageSize={5}
      showRowActions={false}
      searchId={detailedSubject?.id}
      additionalUrlParameter={{ categoryexamination_set: true }}
      expandedDepth={true}
      tableCollapseWidth={"90%"}
    />
  );
};

export default SubjectOverviewAppointments;
