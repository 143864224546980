import { FC } from "react";
import Restricted from "../consumer/restricted";
import WorkerAppointmentsTable from "../components/workerAppointments/workerAppointmentsTable";

const WorkerAppointments: FC = () => {
  return (
    <Restricted permissionRequired="frontend_view_worker_appointments">
      <WorkerAppointmentsTable />
    </Restricted>
  );
};

export default WorkerAppointments;
