import {
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { FC, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import CustomErrorMessage from "../inputsWithRef/customErrorMessage";
import styles from "./passwordInput.module.css";

interface Props {
  id: any;
  label: any;
  title: any;
  dynamicLabel: boolean;
  backgroundColor?: string;
  control: any;
  name: any;
  required?: any;
  requiredErrorMessage?: any;
  pattern?: any;
  patternErrorMessage?: any;
  validationRule?: any;
  validationRuleErrorMessage?: any;
  errorFontColor?: any;
  customLabelStyle?: React.CSSProperties;
  customInputStyle?: React.CSSProperties;
  labelClassName?: string;
  inputClassName?: string;
}

const PasswordInput: FC<Props> = ({
  id,
  label,
  title,
  dynamicLabel,
  backgroundColor,
  control,
  name,
  required,
  requiredErrorMessage,
  pattern,
  patternErrorMessage,
  validationRule,
  validationRuleErrorMessage,
  errorFontColor,
  customLabelStyle,
  customInputStyle,
  labelClassName,
  inputClassName,
}) => {
  const [showPassword, setShowPassword] = useState<any>(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const labelStyle = {
    marginTop: "1rem",
    marginBottom: "1rem",
    gap: "0.5rem",
    color: "white",
    width: "100%",
    ...customLabelStyle,
  } as React.CSSProperties;

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{
          required: { value: required, message: requiredErrorMessage },
          pattern: {
            value: pattern,
            message: patternErrorMessage,
          },

          validate: {
            value: (value: any) => {
              if (
                (name === "confirmNewPassword" ||
                  name === "confirmedPassword") &&
                value !== validationRule
              ) {
                return validationRuleErrorMessage;
              }
            },
          },
        }}
        render={({ field: { onChange, ...rest }, formState: { errors } }) => (
          <FormControl sx={{ width: "100%" }}>
            {dynamicLabel ? (
              <InputLabel htmlFor={id}>{label}</InputLabel>
            ) : (
              <label
                className={labelClassName}
                style={labelStyle}
                htmlFor={id}
              >
                {label}
              </label>
            )}
            <OutlinedInput
              {...rest}
              classes={{
                root: styles.outlinedInputRoot,
                notchedOutline: !dynamicLabel ? styles.outlinedInputNotchedOutlineNone : "",
                input: styles.inputBaseInputPadding,
              }}
              sx={{ ...customInputStyle }}
              className={`
                ${inputClassName}
                ${backgroundColor ? backgroundColor : styles.inputBaseInputDefaultBackground}
              `}
              id={id}
              label={dynamicLabel ? label : ""}
              title={dynamicLabel ? title : ""}
              type={showPassword ? "text" : "password"}
              onChange={onChange}
              endAdornment={
                <IconButton onClick={handleShowPassword}>
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              }
            />

            <ErrorMessage
              errors={errors}
              name={name as string}
              render={({ message }) => (
                <CustomErrorMessage
                  error={"error"}
                  errorMessage={message}
                  errorFontColor={errorFontColor}
                />
              )}
            />
          </FormControl>
        )}
      />
    </>
  );
};

export default PasswordInput;
