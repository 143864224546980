import { FC, useEffect, useState } from "react";
import GenericCrudTable from "../tables/genericCrudTable";
import { t } from "i18next";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
} from "@mui/material";
import DropDownSubjectSearch from "../forms/inputs/dropDownSubjectSearch";
import style from "./companyManagerTable.module.css";
import DropDown from "../forms/inputs/dropDown";
import { api } from "../../helper/api";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import GenericNotification from "../notification/genericNotification";
import { useSelector } from "react-redux";
import { RootState } from "../state/store";

interface Props {
  subjectId?: any;
  companyId?: any;
  subdomain?: any;
}

const CompanyManagerTable: FC<Props> = ({
  subjectId,
  companyId,
  subdomain,
}) => {
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [selectedManagerSubject, setSelectedManagerSubject] =
    useState<string>("");
  const [subjectSearchAllCompanies, setSubjectSearchAllCompanies] =
    useState<boolean>(false);
  const [selectedManagerTypeOption, setSelectedManagertypeOption] =
    useState<any>();
  const [error, setError] = useState<any>();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();
  const [departmentOptions, setDepartmentOptions] = useState<any>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<any>([]);

  const title = t("add_new_manager");
  const submitButtonText = t("add");

  const managerCompany = useSelector((state: RootState) => state.login);
  const selectedChildCompany = useSelector((state: RootState) => state.parent);

  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
    setNotificationMessage("");
  };

  const getDepartments = () => {
    const additionalUrlParameter: any = {};
    if (subdomain === "admin") {
      additionalUrlParameter["impersonate_subject"] = subjectId;
    }
    api.genericApiRequest({
      entity: "department",
      method: "get",
      parametersToRender: { additionalUrlParameters: additionalUrlParameter },
      successHandler: (res: any) => {
        const data = res.data.results;
        const departmentOptions = [];
        for (const key in data) {
          departmentOptions.push({
            value: data[key].id,
            label: data[key].name,
          });
        }
        setDepartmentOptions(departmentOptions);
      },
      failHandler: (error: any) => {
        console.log(error);
      },
    });
  };

  useEffect(() => {
    if (selectedManagerTypeOption?.value === "department_manager") {
      getDepartments();
    }
  }, [selectedManagerTypeOption]);

  const managerColumns = [
    { header: "id", accessorKey: "id", id: "id" },
    {
      header: t("name"),
      accessorKey: "subject.name",
      id: "name",
    },
    {
      header: t("company"),
      accessorKey: "company.name",
      id: "company",
    },
    {
      header: t("type"),
      accessorKey: "manager_type",
      enableForCreate: true,
      selectOptions: [
        {
          value: "standard_manager",
          label: t("standard_manager"),
          id: "standard_manager",
        },
        // {
        //   value: "department_manager",
        //   label: t("department_manager"),
        //   id: "department_manager",
        // },
      ],
      id: "manager_type",
      accessorFn: (row: any) => {
        return t(row.manager_type);
      },
    },
    // {
    //   header: t("departments"),
    //   enableForCreate: true,
    //   entity: "department",
    //   multiselect: true,
    //   accessorKey: "managed_department_set",
    //   id: "managed_department_set",
    //   accessorFn: (row: any) => {
    //     const departments: any = [];
    //     if (row.managed_department_set) {
    //       for (const key in row.managed_department_set) {
    //         if (row.managed_department_set) {
    //           departments.push(row.managed_department_set[key].name);
    //         }
    //       }
    //     }
    //     return departments.join(", ");
    //   },
    // },
  ];

  const handleSubjectSelect = (selectedOption: any) => {
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.value
    ) {
      setSelectedManagerSubject(selectedOption.value);
    }
  };
  const handleCheckboxChange = (event: any) => {
    setSubjectSearchAllCompanies(event.target.checked);
  };

  const onCloseDialog = () => {
    setOpenCreateModal(false);
    setSubjectSearchAllCompanies(false);
  };

  const managerOptions = [
    { value: "standard_manager", label: t("standard_manager") },
    // { value: "department_manager", label: t("department_manager") },
  ];

  const handleSelectedManagerOptionChange = (selectedOption: any) => {
    setSelectedManagertypeOption(selectedOption);
  };
  const handleSelectedDepartmentsChange = (selectedOption: any) => {
    setSelectedDepartments(selectedOption);
  };
  const handleSubmit = () => {
    let companyIdForSubmit = companyId;

    if (subdomain === "manage" && selectedChildCompany.companyId !== "") {
      companyIdForSubmit = selectedChildCompany.companyId;
    }

    const result: any = {
      company: companyIdForSubmit,
      subject: selectedManagerSubject,
      manager_type: selectedManagerTypeOption.value,
    };
    const additionalUrlParameters: any = {};
    if (subdomain === "manage") {
      if (selectedChildCompany.subjectId !== "") {
        additionalUrlParameters["impersonate_subject"] =
          selectedChildCompany.subjectId;
        result.impersonate_subject = selectedChildCompany.subjectId;
      } else
        additionalUrlParameters["impersonate_subject"] =
          managerCompany.subjectId;
    }

    if (selectedDepartments.length !== 0) {
      const departmentIds = [];
      for (const key in selectedDepartments) {
        departmentIds.push(selectedDepartments[key].value);
      }
      result.managed_department_set = departmentIds;
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      method: "post",
      entity: "companyManager",
      parametersToRender: { additionalUrlParameters: additionalUrlParameters },
      successHandler: () => {
        setNotificationMessage(t("save_successful"));
        setNotificationVariant("success");
        setShowNotification(true);
        onCloseDialog();
      },
      failHandler: (error: any) => {
        setError(error);
      },
      submitData: result,
      config: config,
    });
  };

  const additionalParameters: any = {};
  const additionalQuickSearchParameter: any = {};
  additionalQuickSearchParameter["include_highest_group"] = "true";
  if (subdomain === "admin") {
    additionalQuickSearchParameter["company_id"] = companyId; //TODO @Ben, we check the existence of companyId below, why not here?
  }
  if (subjectSearchAllCompanies) {
    delete additionalQuickSearchParameter["company_id"];
  }

  if (companyId !== undefined) {
    additionalParameters["company"] = companyId;
    additionalQuickSearchParameter["hide_managers_from_company_id"] = companyId;
  }
  if (subdomain === "manage") {
    additionalParameters["impersonate_subject"] = managerCompany.subjectId;
  }
  const content = (
    <Stack className={style.stackFormWrapper}>
      {subdomain === "admin" && (
        <FormControlLabel
          control={
            <Checkbox
              checked={subjectSearchAllCompanies}
              onChange={handleCheckboxChange}
            />
          }
          label={t("subject_search_all_companies")}
        />
      )}
      <DropDownSubjectSearch
        subdomain={"admin"}
        useGlobalState={false}
        options={[]}
        onChange={handleSubjectSelect}
        wrapperClassName={style.dropDownSubjectSearch}
        queryParameter="users"
        additionalSearchParameters={additionalQuickSearchParameter}
        customLabel={t("employee_search_hint")}
      />
      <DropDown
        label={t("type")}
        options={managerOptions}
        isMulti={false}
        isClearable={true}
        onChange={handleSelectedManagerOptionChange}
      />
      {selectedManagerTypeOption?.value === "department_manager" &&
        departmentOptions.length !== 0 && (
          <DropDown
            label={t("departments")}
            options={departmentOptions}
            isClearable={true}
            isMulti={true}
            onChange={handleSelectedDepartmentsChange}
          />
        )}
      <DialogActions className={style.dialogActions}>
        <Button onClick={onCloseDialog}>{t("cancel")}</Button>
        <Button
          color="secondary"
          type="button"
          variant="contained"
          onClick={handleSubmit}
        >
          {submitButtonText}
        </Button>
      </DialogActions>
    </Stack>
  );

  return (
    <>
      <GenericCrudTable
        columns={managerColumns}
        entity="companyManager"
        companySubjectId={subjectId}
        additionalUrlParameter={additionalParameters}
        customCreateHandler={() => setOpenCreateModal(true)}
      />
      <Dialog
        open={openCreateModal}
        PaperProps={{ className: style.paperProps }}
        onClose={onCloseDialog}
      >
        <DialogTitle className={style.dialogTitle}>{title}</DialogTitle>
        <DialogContent className={style.dialogContent}>{content}</DialogContent>
      </Dialog>
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
      {showNotification && (
        <GenericNotification
          message={notificationMessage}
          variant={notificationVariant}
          handleCloseSnackbar={resetStateOnCloseNotification}
        />
      )}
    </>
  );
};

export default CompanyManagerTable;
