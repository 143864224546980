import { FC } from "react";
import { Grid, List, ListItem, ListItemText } from "@mui/material";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { t } from "i18next";
import styles from "./appointmentTreeItemPersonInformation.module.css";

interface Props {
  appointment: any;
  handleNavigate?: any;
  handleNavigateToCompany?: any;
}

const AppointmentTreeItemPersonInformation: FC<Props> = ({
  appointment,
  handleNavigate,
  handleNavigateToCompany,
}) => {
  const personName = () => {
    const today = dayjs();
    const name = appointment.subject?.name;
    const sex = appointment.subject?.person?.sex;
    const birthDate = dayjs(appointment.subject?.person?.birth_date).format(
      "DD.MM.YYYY"
    );
    const birthForCalculation = dayjs(
      new Date(appointment.subject?.person?.birth_date)
    );

    const age = today.diff(birthForCalculation, "year") + " " + t("year_age");

    let result = name;
    if (sex) {
      let tempSex;
      switch (sex) {
        case "male":
          tempSex = t("male").toLowerCase();
          break;
        case "female":
          tempSex = t("female").toLowerCase();
          break;
        case "diverse":
          tempSex = t("diverse").toLowerCase();
          break;
      }
      result += `, ${tempSex}`;
    }
    if (birthDate) {
      result += `, ${birthDate} (${age})`;
    }

    return <>{result}</>;
  };
  return (
    <Grid item container xs={12} component={List} className={styles.container}>
      <Grid xs={12} item component={ListItem} className={styles.subContainer}>
        <ListItemText
          className={styles.personInfo}
          primary={
            <div className={styles.flexContainer}>
              <Link
                className={styles.link}
                to="/subjectOverview"
                onClick={handleNavigate}
                target="_blank"
              >
                {personName()}
              </Link>
              <a
                className={styles.link}
                href={`mailto:${appointment.subject?.email}`}
              >
                {appointment.subject?.email}
              </a>
            </div>
          }
          primaryTypographyProps={{ className: styles.primaryText }}
        />
      </Grid>
      <Grid xs={12} item component={ListItem} className={styles.subContainer}>
        <ListItemText
          className={styles.companyInfo}
          // primary={appointment.subject?.company?.name}
          primary={
            <div className={styles.flexContainer}>
              <Link
                className={styles.link}
                to="/subjectOverview"
                target="_blank"
                onClick={handleNavigateToCompany}
              >
                {appointment.subject?.company?.name}
              </Link>
            </div>
          }
          primaryTypographyProps={{ className: styles.secondaryText }}
        />
      </Grid>
      <Grid xs={12} item component={ListItem} className={styles.subContainer}>
        {appointment.categoryexamination_set?.map((examination: any) => (
          <ListItemText
            className={styles.examinationInfo}
            key={examination.examination}
            primary={examination.examination_name}
            primaryTypographyProps={{ className: styles.primaryText }}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default AppointmentTreeItemPersonInformation;
