import { FC } from "react";
import Restricted from "../../consumer/restricted";
import MailTemplateMainTable from "../../components/mailTemplate/mailTemplateMainTable";

const MailTemplateList: FC = () => {
  return (
    <Restricted permissionRequired="frontend_view_email_template">
      <MailTemplateMainTable />
    </Restricted>
  );
};

export default MailTemplateList;
