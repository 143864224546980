import { Button } from "@mui/material";
import { t } from "i18next";
import { FC, useMemo } from "react";
import GenericCrudTable from "../tables/genericCrudTable";
import { MRT_ColumnDef } from "material-react-table";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import styles from "./subjectOverviewTodos.module.css";

interface Props {
  detailedSubject?: any;
}

const SubjectOverviewTodos: FC<Props> = ({ detailedSubject }) => {
  const customTopToolbarAction = (
    <Button color="secondary" variant="contained" startIcon={<OpenInNewIcon />}>
      <Link
        to={`/admin/todoList`}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
      >
        {t("todo_multi")}
      </Link>
    </Button>
  );
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        id: "id",
        enableHiding: false,
      },
      {
        header: t("due_date"),
        accessorKey: "due_date",
        id: "due_date",
        dateTimePicker: true,
        enableForCreate: true,
        required: true,
        errorMessage: t("field_required"),
        sortingFn: "datetime",
        Cell: ({ cell }: any) => {
          const date = new Date(cell.getValue()).toLocaleString();

          return <span>{date}</span>;
        },
      },
      {
        header: t("name"),
        accessorKey: "name",
        id: "name",
      },
      {
        header: t("description"),
        accessorKey: "description",
        id: "description",
      },
      {
        header: t("done"),
        accessorKey: "done",
        id: "done",
        accessorFn: (row: any) => {
          if (row.done === true) return "Ja";
          else return "Nein";
        },
      },
    ],
    []
  );
  return (
    <GenericCrudTable
      entity={"todo"}
      columns={columns}
      customTopToolBarAction={customTopToolbarAction}
      customGlobalFilterPosition={"left"}
      customDensity="compact"
      customPageSize={5}
      showRowActions={false}
      searchId={detailedSubject?.id}
      tableCollapseWidth={"75%"}
    />
  );
};

export default SubjectOverviewTodos;
