import { Box, Button, Paper } from "@mui/material";
import { t } from "i18next";
import { FC, useMemo } from "react";
import GenericCrudTable from "../tables/genericCrudTable";
import { MRT_ColumnDef } from "material-react-table";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import dayjs from "dayjs";
import styles from "./subjectOverviewAppointmentRequests.module.css";
import { getStatusLabel } from "../../helper/statusOptions";

interface Props {
  detailedSubject?: any;
}

const SubjectOverviewAppointmentRequests: FC<Props> = ({ detailedSubject }) => {
  const customTopToolbarAction = (
    <Button color="secondary" variant="contained" startIcon={<OpenInNewIcon />}>
      <Link
        to="/appointmentrequest"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link_white}
      >
        {t("appointmentrequest_multi")}
      </Link>
    </Button>
  );
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        id: "id",
        enableHiding: false,
      },
      {
        header: t("date"),
        accessorKey: "day_set",
        id: "day_set",
        accessorFn: (row: any) => {
          const daysInRange = row.day_set.length;
          let date;
          if (row.day_set.length !== 0) {
            const set = row.day_set;
            const start = dayjs(set[0].start).format("DD.MM.YYYY");
            const end = dayjs(set[daysInRange - 1].end).format("DD.MM.YYYY");

            if (start === end) {
              date = start;
            } else {
              date = start + " - " + end;
            }
            // }
            return (
              <Box>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link_purple}
                  to={`/editappointmentrequest/${row.id}/building`}
                >
                  {date}
                </Link>
              </Box>
            );
          }
        },
      },
      {
        header: t("department"),
        accessorKey: "department_set",
        id: "department_set",
        accessorFn: (row: any) => {
          const departments = [];
          if (row.department_set) {
            for (const key in row.department_set) {
              if (row.department_set[key].name) {
                departments.push(row.department_set[key].name);
              }
            }
          }
          return departments.join(", ");
        },
      },
      {
        header: t("category"),
        accessorKey: "category",
        id: "category",
        accessorFn: (row: any) => {
          if (row.category) {
            const name = row.category.name;
            return name;
          }
        },
      },
      {
        header: t("name"),
        accessorKey: "title",
        id: "title",
      },
      {
        header: t("status"),
        accessorKey: "status",
        id: "status",
        accessorFn: (row: any) => {
          const appointmentStatus = getStatusLabel(row?.status);
          return <span>{appointmentStatus}</span>;
        },
      },
      {
        header: t("executed_by"),
        accessorKey: "executed_by",
        id: "executed_by",
        enableForCreate: true,
        entity: "user",
        accessorFn: (row: any) => {
          if (row.executed_by && row.executed_by !== null) {
            return (
              row.executed_by.last_name + ", " + row.executed_by.first_name
            );
          } else return "-";
        },
      },
    ],
    []
  );
  return (
    <Paper className={styles.paperContainer}>
      {/* <h5>{t("appointmentrequest_multi")}</h5> */}
      <GenericCrudTable
        entity={"appointmentRequest"}
        columns={columns}
        customTopToolBarAction={customTopToolbarAction}
        customGlobalFilterPosition={"left"}
        customDensity="compact"
        customPageSize={5}
        showRowActions={false}
        searchId={detailedSubject?.company.id}
        tableCollapseWidth={"75%"}
      />
    </Paper>
  );
};

export default SubjectOverviewAppointmentRequests;
