import { useRef, useState } from "react";
import DropDownSubjectSearch from "../../components/forms/inputs/dropDownSubjectSearch";
import GenericCrudTable from "../../components/tables/genericCrudTable";
import { useTranslation } from "react-i18next";
import { IDropDownOption } from "../../types/DropDown";
import Restricted from "../../consumer/restricted";
import styles from "./todoList.module.css";

export default function TodoList() {
  /*eslint-disable */
  const [options, setOptions] = useState<IDropDownOption[]>([]);
  /*eslint-enable */
  const [subjectId, setSubjectId] = useState<string>("");
  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  const { t } = useTranslation();
  const todoRef = useRef(null);

  const replaceDate = (id: any) => {
    const date = new Date(id).toLocaleString();
    return date;
  };
  const handleSubjectSelect = (selectedOption: any) => {
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.value
    ) {
      setSubjectId(selectedOption.value);
    }
  };

  const resetAsyncInput = () => {
    setSubjectId("");
  };

  // currently disabled - enable again when tagging logic for todos is implemented

  // const additionalStaffColumn = () => {
  //   const column = [
  //     {
  //       header: t("kaer_staff_todo_question"),
  //       accessorKey: "is_staff",
  //       id: "staff",
  //       checkBox: true,
  //       enableForCreate: true,
  //       columnOnTop: true,
  //     },
  //   ];

  //   if (subdomain === "admin") {
  //     return column;
  //   }
  //   return;
  // };

  return (
    <Restricted permissionRequired="frontend_view_todo_table">
      <DropDownSubjectSearch
        wrapperClassName={styles.dropDownSubjectSearch}
        options={options}
        onChange={handleSubjectSelect}
        subdomain={subdomain}
        isClearable={true}
        resetInput={resetAsyncInput}
        customRef={todoRef}
        autofocus={true}
        useGlobalState={true}
      />

      <GenericCrudTable
        allowColumnFiltering={true}
        showColumnFilterbyDefault={true}
        withSubjectSearch={true}
        heading={t("todo_multi")}
        searchId={subjectId}
        entity={"todo"}
        columns={[
          { header: "ID", accessorKey: "id", id: "id" },
          {
            header: t("subject"),
            accessorKey: "subject.name",
            id: "subject",
            enableForCreate: true,
            asyncEntity: "subject",
            asyncDropDown: true,
            customColumnFilterId: "subject_name",
          },
          {
            header: t("name"),
            accessorKey: "name",
            id: "name",
            enableForCreate: true,
            required: true,
            errorMessage: t("field_required"),
          },
          {
            header: t("type"),
            accessorKey: "type",
            required: true,
            filterVariant: "select",
            filterSelectOptions: [
              { value: "form", label: "Formular", id: "form" },
              { value: "upload", label: "Dateiupload", id: "upload" },
              { value: "text", label: "Text", id: "text" },
            ],
            errorMessage: t("field_required"),
            id: "type",
            enableForCreate: true,
            selectOptions: [
              { value: "form", label: t("form"), id: "form" },
              { value: "upload", label: t("file_upload"), id: "upload" },
              { value: "text", label: t("string"), id: "text" },
            ],
          },
          {
            header: t("description"),
            accessorKey: "description",
            enableForCreate: true,
            id: "description",
            required: true,
            errorMessage: t("field_required"),
          },
          {
            header: t("target"),
            accessorKey: "target",
            customColumnFilterId: "target",
            customFilterParameter: "_icontains",
            enableColumnFilter: false,
            id: "target",
            enableForCreate: true,
            accessorFn: (row: any) => {
              const targetData = [];
              if (row.target) {
                targetData.push(JSON.stringify(row.target));
              }

              return targetData.join(", ");
            },
            isJson: true,
          },
          {
            header: t("done"),
            accessorKey: "done",
            id: "done",
            enableForCreate: true,
            required: true,
            filterVariant: "select",
            filterSelectOptions: [
              { value: "true", label: t("yes"), id: "true" },
              { value: "false", label: t("no"), id: "false" },
            ],
            errorMessage: t("field_required"),
            accessorFn: (row: any) => {
              if (row.done === true) return "Ja";
              else return "Nein";
            },
            selectOptions: [
              { value: "true", label: t("yes"), id: "true" },
              { value: "false", label: t("no"), id: "false" },
            ],
          },
          {
            header: t("due_date"),
            accessorKey: "due_date",
            id: "due_date",
            filterVariant: "date",
            dateTimePicker: true,
            enableForCreate: true,
            required: true,
            errorMessage: t("field_required"),
            sortingFn: "datetime",
            Cell: ({ cell }: any) => {
              return <span>{replaceDate(cell.getValue())}</span>;
            },
          },
          {
            header: t("created_on"),
            accessorKey: "created_on",
            sortingFn: "datetime",
            filterVariant: "date",

            id: "created_on",
            Cell: ({ cell }: any) => {
              return <span>{replaceDate(cell.getValue())}</span>;
            },
          },
          {
            header: t("created_by"),
            accessorKey: "created_by",
            customColumnFilterId: "created_by_name",
            id: "created_by",
            Cell: ({ cell }: any) => {
              return <span>{cell.getValue().name}</span>;
            },
          },
          {
            header: t("modified_on"),
            accessorKey: "modified_on",
            sortingFn: "modified_on",
            filterVariant: "date",

            id: "modified_on",
            Cell: ({ cell }: any) => {
              return <span>{replaceDate(cell.getValue())}</span>;
            },
          },
          {
            header: t("modified_by"),
            accessorKey: "modified_by",
            customColumnFilterId: "modified_by_name",
            id: "modified_by",
            Cell: ({ cell }: any) => {
              return <span>{cell.getValue().name}</span>;
            },
          },
        ]}
        // additionalColumnsForCreate={additionalStaffColumn()}
      />
    </Restricted>
  );
}
