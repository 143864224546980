//dateTime boolean for showing data with HH:mm - default is false for just showing DD.MM.YYYY

export const replaceDate = (date: any, dateTime = false) => {
  let tempDate = "";
  if (dateTime === true) {
    tempDate = new Date(date).toLocaleString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  } else {
    tempDate = new Date(date).toLocaleString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  }

  if (date === null || date === undefined) {
    return "";
  } else return tempDate;
};
