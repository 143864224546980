import { FC } from "react";
import { ListItem } from "@mui/material";
import "./navigationBar.css";
import {
  NavigationItemButton,
  NavigationListItemContent,
  NavigationNavLink,
} from "./navigationItems";
import Restricted from "../../consumer/restricted";

interface Props {
  drawerOpen: boolean;
  selectedIndex: number;
  handleListItemClick?: any;
  subdomain: string;
}

const CustomerRoutes: FC<Props> = ({
  drawerOpen,
  selectedIndex,
  handleListItemClick,
  subdomain,
}) => {
  if (subdomain === "portal") {
    return (
      <>
        <Restricted permissionRequired="frontend_view_user_appointment">
          <ListItem
            disablePadding
            className={drawerOpen ? "navigationItemOpen" : "navigationItem"}
          >
            <NavigationNavLink to="appointmentrequestopen">
              <NavigationItemButton
                drawerOpen={drawerOpen}
                onClick={(event: any) => handleListItemClick(event, 50)}
                selected={selectedIndex === 50}
              >
                <NavigationListItemContent
                  drawerOpen={drawerOpen}
                  primaryText="Termine"
                  icon="appointments"
                />
              </NavigationItemButton>
            </NavigationNavLink>
          </ListItem>
        </Restricted>
      </>
    );
  } else return <></>;
};

export default CustomerRoutes;
