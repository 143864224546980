import GenericCrudTable from "../../components/tables/genericCrudTable";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DEFAULTPICKERDATE } from "../../types/Entities";
import DropDownSubjectSearch from "../../components/forms/inputs/dropDownSubjectSearch";
import { useRef, useState } from "react";
import { IDropDownOption } from "../../types/DropDown";
import Restricted from "../../consumer/restricted";
import styles from "./appointmentList.module.css";
import { getTranslatedStatusOptions } from "../../helper/statusOptions";

export default function AppointmentList() {
  /*eslint-disable */
  const [options, setOptions] = useState<IDropDownOption[]>([]);
  /*eslint-enable */
  const [subjectId, setSubjectId] = useState<string>("");
  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  const statusFilterOptions = getTranslatedStatusOptions();

  const navigate = useNavigate();

  const { t } = useTranslation();
  const appointmentRef = useRef(null);

  const replaceDate = (id: any) => {
    const date = new Date(id).toLocaleString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
    return date;
  };

  const handleSubjectSelect = (selectedOption: any) => {
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.value
    ) {
      setSubjectId(selectedOption.value);
    }
  };

  const resetAsyncInput = () => {
    setSubjectId("");
  };

  return (
    <Restricted permissionRequired="frontend_view_appointment">
      <DropDownSubjectSearch
        wrapperClassName={styles.dropDownSubjectSearch}
        options={options}
        onChange={handleSubjectSelect}
        subdomain={subdomain}
        isClearable={true}
        resetInput={resetAsyncInput}
        autofocus={true}
        customRef={appointmentRef}
        useGlobalState={true}
      />
      <GenericCrudTable
        allowColumnFiltering={true}
        showColumnFilterbyDefault={true}
        withSubjectSearch={true}
        heading={t("appointments_booked")}
        searchId={subjectId}
        entity={"appointment"}
        subjectIdForBuildings={subjectId}
        customEditHandler={(row: any) => {
          navigate(`/settings/appointmentEditor/${row.original.id}`);
        }}
        additionalUrlParameter={{ include_draft: "true" }}
        expandedDepth={true}
        // additionalUrlParameter={{
        //   impersonate_subject: "11111111-1111-1111-1111-333333333333",
        // }}
        columns={[
          { header: "ID", accessorKey: "id", id: "id" },

          {
            header: t("datetime"),
            accessorKey: "datetime",
            id: "datetime",
            enableForCreate: true,
            dateTimePicker: true,
            sortingFn: "datetime",
            defaultDate: DEFAULTPICKERDATE.TODAY,
            required: true,
            errorMessage: t("field_required"),
            filterVariant: "date",
            accessorFn: (row: any) => {
              const appointmentId = row.id;
              const status = row.status;

              return (
                <>
                  {status !== "draft" ? (
                    <Link
                      to={`/appointmentDashboard/${appointmentId}`}
                      style={{ textDecoration: "none", color: "#8c1ec8" }}
                      target="_blank"
                    >
                      <span>{replaceDate(row.datetime)}</span>
                    </Link>
                  ) : (
                    <>
                      <span>{replaceDate(row.datetime)}</span>
                    </>
                  )}
                </>
              );
            },
          },

          {
            header: t("subject"),
            accessorKey: "subject.name",
            id: "subject",
            enableForCreate: true,
            asyncEntity: "subject",
            asyncDropDown: true,
            required: true,
            customColumnFilterId: "subject_name",
            errorMessage: t("field_required"),
          },
          {
            header: t("company"),
            accessorKey: "subject.company.name",
            id: "company",
            customColumnFilterId: "subject_company_name",
          },

          {
            header: t("team"),
            accessorKey: "team",
            id: "team",
            enableForCreate: true,
            entity: "team",
            required: true,
            customColumnFilterId: "team__name",
            errorMessage: t("field_required"),
            accessorFn: (row: any) => {
              const name = row.team.name;
              return name;
            },
          },
          {
            header: t("status"),
            accessorKey: "status",
            id: "status",
            customFilterParameter: "_icontains",
            filterVariant: "select",
            filterSelectOptions: statusFilterOptions,
            accessorFn: (row: any) => {
              const status = row.status;
              const string = "status_" + status;

              return `${t(string)} (${status})`;
            },
          },
          {
            header: "Terminart",
            size: 40,
            enableColumnFilter: false,
            accessorFn: (row: any) => {
              const appointmentRequestId = row.appointment_request_id;
              if (appointmentRequestId == null) {
                return <span>{t("appointment_manual")}</span>;
              } else
                return (
                  <Link
                    to={`/editappointmentrequest/${appointmentRequestId}/building`}
                    className={styles.link}
                  >
                    {t("appointmentrequest_single")}
                  </Link>
                );
            },
          },
          {
            header: t("created_on"),
            accessorKey: "created_on",
            id: "created_on",
            sortingFn: "datetime",
            filterVariant: "date",

            Cell: ({ cell }: any) => {
              return <span>{replaceDate(cell.getValue())}</span>;
            },
          },
          {
            header: t("created_by"),
            accessorKey: "created_by",
            id: "created_by",
            customColumnFilterId: "created_by_name",
            Cell: ({ cell }: any) => {
              return <span>{cell.getValue().name}</span>;
            },
          },
          {
            header: t("modified_on"),
            accessorKey: "modified_on",
            id: "modified_on",
            sortingFn: "datetime",
            filterVariant: "date",

            Cell: ({ cell }: any) => {
              return <span>{replaceDate(cell.getValue())}</span>;
            },
          },
          {
            header: t("modified_by"),
            accessorKey: "modified_by",
            customColumnFilterId: "modified_by_name",
            id: "modified_by",
            Cell: ({ cell }: any) => {
              return <span>{cell.getValue().name}</span>;
            },
          },
          {
            header: t("executed_by"),
            accessorKey: "executed_by",
            customColumnFilterId: "executed_by_name",
            id: "executed_by",
            getOnlyActiveUsers: {
              status: true,
              companySubjectId: "11111111-1111-1111-1111-444444444444",
            },
            enableForCreate: true,
            entity: "user",
            accessorFn: (row: any) => {
              if (row.executed_by && row.executed_by !== null) {
                return row.executed_by.name;
              } else return "-";
            },
          },
          {
            header: t("buildings"),
            entity: "building",
            childEntityName: "building_information",
            accessorKey: "building_information.building",
            customColumnFilterId: "building_information__building__name",
            id: "building",
            accessorFn: (row: any) => {
              const { name, street_house, zip, city } =
                row.building_information;
              const locationParts = [];

              if (name) locationParts.push(name);
              if (street_house) locationParts.push(street_house);

              if (zip || city) {
                const combined = [zip, city]
                  .filter((part) => part !== null)
                  .join(" ");
                locationParts.push(combined);
              }

              return locationParts.join(", ");
            },
          },
          {
            header: t("building_contact_name"),
            childEntityName: "building_information",
            accessorKey: "building_information.contact_name",
            customColumnFilterId: "building_information__contact_name",
            id: "name",
            hideField: "true",
          },
          {
            header: t("building_contact_email"),
            childEntityName: "building_information",
            accessorKey: "building_information.contact_email",
            id: "email",
            idForFiltering: "building_information",
            hideField: "true",
            customColumnFilterId: "building_information__contact_email",
          },
          {
            header: t("building_contact_phone"),
            childEntityName: "building_information",
            accessorKey: "building_information.contact_phone",
            customColumnFilterId: "building_information__contact_phone",
            id: "phone",
            hideField: "true",
          },
          {
            header: t("building_parking_situation"),
            childEntityName: "building_information",
            customColumnFilterId: "building_information__parking_situation",
            accessorKey: "building_information.parking_situation",
            id: "parking_situation",
            hideField: "true",
          },
          {
            header: t("building_additional_information"),
            childEntityName: "building_information",
            customColumnFilterId:
              "building_information__additional_information",
            accessorKey: "building_information.additional_information",
            id: "additional_information",
            hideField: "true",
          },
        ]}
        customColumnVisibility={{
          name: false,
          email: false,
          phone: false,
          parking_situation: false,
          additional_information: false,
        }}
        // additionalColumnsForCreate={[
        //   {
        //     header: t("building_contact_name"),
        //     childEntityName: "building_information",
        //     accessorKey: "building_information.contact_name",
        //     id: "contact.name",
        //     enableForCreate: true,
        //   },
        //   {
        //     header: t("building_contact_email"),
        //     childEntityName: "building_information",
        //     accessorKey: "building_information.contact_email",
        //     id: "contact.email",
        //     enableForCreate: true,
        //   },
        //   {
        //     header: t("building_contact_phone"),
        //     childEntityName: "building_information",
        //     accessorKey: "building_information.contact_phone",
        //     id: "contact.phone",
        //     enableForCreate: true,
        //   },
        //   {
        //     header: t("building_parking_situation"),
        //     childEntityName: "building_information",
        //     accessorKey: "building_information.parking_situation",
        //     id: "contact.parking_situation",
        //     enableForCreate: true,
        //   },
        //   {
        //     header: t("building_additional_information"),
        //     childEntityName: "building_information",
        //     accessorKey: "building_information.additional_information",
        //     id: "contact.additional_information",
        //     enableForCreate: true,
        //   },
        // ]}
        // additionalColumnsForEdit={[
        //   {
        //     header: t("contact"),
        //     childEntityName: "building_information",
        //     accessorKey: "building_information.contact_name",
        //     id: "contact.name",
        //     enableForCreate: true,
        //   },
        //   {
        //     header: t("building_contact_email"),
        //     childEntityName: "building_information",
        //     accessorKey: "building_information.contact_email",
        //     id: "contact.email",
        //     enableForCreate: true,
        //   },
        //   {
        //     header: t("building_contact_phone"),
        //     childEntityName: "building_information",
        //     accessorKey: "building_information.contact_phone",
        //     id: "contact.phone",
        //     enableForCreate: true,
        //   },
        //   {
        //     header: t("building_parking_situation"),
        //     childEntityName: "building_information",
        //     accessorKey: "building_information.parking_situation",
        //     id: "contact.parking_situation",
        //     enableForCreate: true,
        //   },
        //   {
        //     header: t("building_additional_information"),
        //     childEntityName: "building_information",
        //     accessorKey: "building_information.additional_information",
        //     id: "contact.additional_information",
        //     enableForCreate: true,
        //   },
        // ]}
      />
    </Restricted>
  );
}
