import { FC } from "react";
import GenericCrudTable from "../tables/genericCrudTable";
import { t } from "i18next";
import { IconButton, Tooltip } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import { useNavigate } from "react-router-dom";

interface Props {
  title?: string;
}

const GroupCrudTable: FC<Props> = () => {
  const navigate = useNavigate();

  const columns = [
    { header: "id", accessorKey: "id" },
    { header: t("name"), accessorKey: "name", enableForCreate: true },
  ];
  const customRowActions = (row: any) => {
    return (
      <Tooltip
        arrow
        placement="left"
        title={t("group_edit_permissions", { group: row.original.name })}
      >
        <IconButton
          onClick={() =>
            navigate(`/permissionGroupList/${row.original.id}/edit`)
          }
        >
          <KeyIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const additionalUrlParameters: any = {};
  additionalUrlParameters["include_kaer_internal"] = true;
  return (
    <>
      <GenericCrudTable
        entity="group"
        columns={columns}
        additionalUrlParameter={additionalUrlParameters}
        additionalSubmitDataForCreate={{ permissions: [] }}
        customRowActions={customRowActions}
      />
    </>
  );
};

export default GroupCrudTable;
