import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { useTranslation } from "react-i18next";

interface Props {
  submitButtonText?: string | null;
  cancelButtonText?: string;
  hideSubmitButton?: boolean;
  hideDialogActions?: boolean;
  onSubmit?: () => void;
  open: boolean;
  title?: string;
  onClose?: () => void;
  children?: any;
  presetValues?: any;
  disableSubmit?: boolean;
  contentStyle?: React.CSSProperties;
  contentClassName?: string;
  buttonPlacement?:
    | "center"
    | "space-around"
    | "space-evenly"
    | "space-between"
    | string
    | null
    | undefined;
}

export default function Modal(props: Props) {
  const { t } = useTranslation();

  let submitButtonText = t("save");
  let cancelButtonText = t("cancel");

  if (props.submitButtonText) {
    submitButtonText = props.submitButtonText;
  }

  if (props.cancelButtonText) {
    cancelButtonText = props.cancelButtonText;
  }

  let submitButton = <></>;

  if (!props.hideSubmitButton) {
    submitButton = (
      <Button
        color="secondary"
        onClick={props.onSubmit}
        variant="contained"
        disabled={props.disableSubmit}
      >
        {submitButtonText}
      </Button>
    );
  }

  let dialogActions = <></>;

  if (!props.hideDialogActions) {
    dialogActions = (
      <DialogActions
        sx={{ p: "1.25rem", justifyContent: props.buttonPlacement }}
      >
        <Button onClick={props.onClose}>{cancelButtonText}</Button>
        {submitButton}
      </DialogActions>
    );
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      PaperProps={{
        sx: { borderRadius: "0.75rem", width: "100%" },
      }}
    >
      <DialogTitle textAlign="center">{props.title}</DialogTitle>
      <DialogContent className={props.contentClassName} sx={props.contentStyle}>
        {props.children}
      </DialogContent>
      {dialogActions}
    </Dialog>
  );
}
