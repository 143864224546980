import { FC, useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Button } from "@mui/material";
import { ITemplateData } from "../../types/TeamplateData";
import { t } from "i18next";
import styles from "./tinymce.module.css";

interface Props {
  value?: ITemplateData | any;
  handleOnSave?: (content: any) => void;
  lastSaved?: any;
  onChange?: (content: any) => void;
  showFooter?: boolean;
}
const TinyMceEditor: FC<Props> = ({
  value,
  handleOnSave,
  lastSaved,
  onChange,
  showFooter = true,
}) => {
  const [localValue, setLocalValue] = useState<any>(value);
  const editorRef = useRef<any>(null);

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
  }, [value]);
  const onChangeHandler = (content: any) => {
    setLocalValue(content);
    if (onChange) {
      onChange(content);
    }
  };

  const onSavehandler = () => {
    const content = editorRef.current.getContent();
    if (handleOnSave) {
      handleOnSave(content);
    }
  };

  return (
    <div className={styles.editorContainer}>
      <Editor
        onEditorChange={onChangeHandler}
        tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
        onInit={(evt, editor) => {
          editorRef.current = editor;
          editor.setContent(localValue);
        }}
        value={localValue}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "preview",
            "help",
            "wordcount",
            "pagebreak",
            "paste",
          ],
          paste_as_text: true,
          remove_trailing_brs: false,
          pagebreak_separator: '<div style="break-after:page"></div>',
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | hr pagebreak | image editimage | link | preview | table | code | help ",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      {showFooter && (
        <div className={styles.footerContainer}>
          <Button
            color="secondary"
            variant="contained"
            type="button"
            onClick={onSavehandler}
            style={{ fontSize: "inherit" }}
          >
            Speichern
          </Button>
          {lastSaved && typeof (lastSaved === "string") && (
            <div className={styles.lastSaved}>
              {t("last_saved")}: <span>{lastSaved}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TinyMceEditor;
