{
  /* <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="56" height="56" rx="28" fill="#EADFF0"/>
<path d="M41.024 25.2188H16.0443C15.0521 25.2188 14.3518 26.0942 14.5269 27.0281L16.7446 38.7005C16.9197 39.4593 17.5618 39.9846 18.2622 39.9846H38.7476C39.5064 39.9846 40.1483 39.4593 40.265 38.7005L42.4827 27.0281C42.7163 26.0942 41.9575 25.2188 41.0238 25.2188H41.024Z" fill="#8C1EC8"/>
<path d="M30.1102 21.1337H29.0597C28.651 21.1337 28.3009 20.9586 28.0091 20.7251L25.7914 18.7406C25.4996 18.4489 25.1495 18.332 24.7409 18.332L18.5542 18.3322C17.6788 18.3322 16.9784 19.0326 16.9784 19.8496V23.6431H30.1101L30.1102 21.1337Z" fill="#8C1EC8"/>
<path d="M37.0547 23.7019H40.0313V22.7098C40.0313 21.8343 39.3309 21.1924 38.5139 21.1924H37.0547V23.7019Z" fill="#8C1EC8"/>
<path d="M30.2847 19.3247H31.3352V24.0521H35.946V19.3247H36.9965C37.7553 19.3247 38.2221 18.5077 37.8135 17.8655L34.4868 12.4378C34.1366 11.8541 33.2612 11.8541 32.8526 12.4378L29.5259 17.8655C29.0591 18.5077 29.5259 19.3247 30.2847 19.3247Z" fill="#8C1EC8"/>
</svg> */
}

import { FC } from "react";

interface Props {
  width?: string;
  height?: string;
  color?: string;
}

const UploadIcon: FC<Props> = ({ width, height, color }) => {
  let iconWidth = "24";
  let iconHeight = "24";
  let iconColor = "black";

  if (width) {
    iconWidth = width;
  }

  if (height) {
    iconHeight = height;
  }
  if (color) {
    // eslint-disable-next-line
    iconColor = color;
  }
  return (
    <svg
      width={iconWidth}
      height={iconHeight}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="56"
        height="56"
        rx="28"
        //   fill="#EADFF0"
        fill="#FFF"
      />
      <path
        d="M41.024 25.2188H16.0443C15.0521 25.2188 14.3518 26.0942 14.5269 27.0281L16.7446 38.7005C16.9197 39.4593 17.5618 39.9846 18.2622 39.9846H38.7476C39.5064 39.9846 40.1483 39.4593 40.265 38.7005L42.4827 27.0281C42.7163 26.0942 41.9575 25.2188 41.0238 25.2188H41.024Z"
        fill="#8C1EC8"
      />
      <path
        d="M30.1102 21.1337H29.0597C28.651 21.1337 28.3009 20.9586 28.0091 20.7251L25.7914 18.7406C25.4996 18.4489 25.1495 18.332 24.7409 18.332L18.5542 18.3322C17.6788 18.3322 16.9784 19.0326 16.9784 19.8496V23.6431H30.1101L30.1102 21.1337Z"
        fill="#8C1EC8"
      />
      <path
        d="M37.0547 23.7019H40.0313V22.7098C40.0313 21.8343 39.3309 21.1924 38.5139 21.1924H37.0547V23.7019Z"
        fill="#8C1EC8"
      />
      <path
        d="M30.2847 19.3247H31.3352V24.0521H35.946V19.3247H36.9965C37.7553 19.3247 38.2221 18.5077 37.8135 17.8655L34.4868 12.4378C34.1366 11.8541 33.2612 11.8541 32.8526 12.4378L29.5259 17.8655C29.0591 18.5077 29.5259 19.3247 30.2847 19.3247Z"
        fill="#8C1EC8"
      />
    </svg>
  );
};

export default UploadIcon;
