import { ErrorMessage } from "@hookform/error-message";
import { FC, HTMLInputTypeAttribute, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import CustomErrorMessage from "../inputsWithRef/customErrorMessage";
import { TextField, Typography } from "@mui/material";
import { t } from "i18next";
import styles from "./genericInputField.module.css";
import { MuiTelInput } from 'mui-tel-input';

interface Props {
  name: string;
  label: string;
  inputType: HTMLInputTypeAttribute;
  predefinedvalue?: any;
}

const GenericInputField: FC<Props> = ({
  name,
  label,
  inputType,
  predefinedvalue,
}) => {
  const [inputValue, setInputValue] = useState<any>("");
  const { setValue, control } = useFormContext();

  useEffect(() => {
    if (predefinedvalue) {
      if (predefinedvalue === null) {
        setInputValue("");
        setValue(name, "");
      } else {
        setInputValue(predefinedvalue);
        setValue(name, predefinedvalue);
      }
    }
  }, [predefinedvalue]);

  const onChangeHandler = (newValue: any, onChange?: any) => {
    const value = newValue.target.value;
    onChange(value);
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, ...rest },
          fieldState: { error },
          formState: { errors },
        }) => (
          <>
            {inputType === "tel" ? (
              <div>
                <Typography variant="body1" className={styles.phoneInputLabel}>
                  {label}
                </Typography>
                <MuiTelInput
                  placeholder={t("input_your_phone_number")}
                  defaultCountry="DE"
                  langOfCountryName="DE"
                  value={inputValue || ""}
                  onChange={(value: string) => {
                    if (value) {
                      onChange?.(value);
                      setInputValue(value);
                    } else {
                      onChange?.("");
                      setInputValue("");
                    }
                  }}
                  className={styles.fullWidth}
                />
              </div>
            ) : (
              <TextField
                {...rest}
                error={error ? true : false}
                type={inputType}
                label={label}
                name={name}
                onChange={(event) => {
                  onChangeHandler(event, onChange),
                    setInputValue(event.target.value);
                }}
                className={styles.fullWidth}
                value={inputValue}
                InputProps={{
                  classes: { root: styles.outlinedInputRounded },
                }}
              />
            )}
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <CustomErrorMessage error={"error"} errorMessage={message} />
              )}
            />
          </>
        )}
      />
    </>
  );
};

export default GenericInputField;
