import { FC } from "react";
import { Grid, List, ListItem, ListItemText } from "@mui/material";
import { t } from "i18next";
import { Link } from "react-router-dom";
import styles from "./appointmentTreeItemCompanyInformation.module.css";

interface Props {
  appointment: any;
  handleNavigate?: any;
}

const AppointmentTreeItemCompanyInformation: FC<Props> = ({
  appointment,
  handleNavigate,
}) => {
  const getAppointmentAddress = () => {
    const buildingInformation = appointment.building_information;

    if (
      buildingInformation.street_house &&
      buildingInformation.city &&
      buildingInformation.zip
    ) {
      return (
        buildingInformation.street_house +
        ", " +
        buildingInformation.zip +
        " " +
        buildingInformation.city
      );
    } else {
      return "-";
    }
  };

  const getAppointmentContact = () => {
    const buildingInformation = appointment.building_information;

    if (
      buildingInformation.contact_name ||
      buildingInformation.contact_email ||
      buildingInformation.contact_phone
    ) {
      return (
        buildingInformation?.contact_name +
        ", " +
        buildingInformation.contact_phone
      );
    } else {
      return "-";
    }
  };
  const getAppointmentContactEmail = () => {
    const buildingInformation = appointment.building_information;

    if (buildingInformation.contact_email) {
      return (
        <a
          className={styles.emailLink}
          href={`mailto:${buildingInformation.contact_email}`}
        >
          {buildingInformation.contact_email}
        </a>
      );
    } else {
      return "-";
    }
  };
  const getAppointmentBuilding = () => {
    const buildingInformation = appointment.building_information;

    if (buildingInformation.name) {
      return buildingInformation.name;
    } else {
      return "-";
    }
  };
  const getAppointmentAdditionalInformation = () => {
    const buildingInformation = appointment.building_information;

    if (buildingInformation.additional_information) {
      return buildingInformation.additional_information;
    } else {
      return "-";
    }
  };
  const getAppointmentParkingSituation = () => {
    const buildingInformation = appointment.building_information;

    if (buildingInformation.parking_situation) {
      return buildingInformation.parking_situation;
    } else {
      return "-";
    }
  };

  const customListItem = (props: any) => {
    return (
      <ListItem {...props} sx={{ padding: "0" }}>
        {props.children}
      </ListItem>
    );
  };
  return (
    <Grid item container xs={12} component={List} className={styles.container}>
      <Grid xs={4} item component={customListItem}>
        <ListItemText
          primary={t("building_subject")}
          secondary={
            <Link
              className={styles.link}
              to="/subjectOverview"
              onClick={handleNavigate}
              target="_blank"
            >
              {appointment.subject.name}
            </Link>
          }
          primaryTypographyProps={{ className: styles.primaryText }}
          secondaryTypographyProps={{ className: styles.secondaryText }}
        />
      </Grid>
      <Grid xs={4} item component={customListItem}>
        <ListItemText
          primary={t("company")}
          secondary={appointment.subject.company.name}
          primaryTypographyProps={{ className: styles.primaryText }}
          secondaryTypographyProps={{ className: styles.secondaryText }}
        />
      </Grid>

      <Grid xs={4} item component={customListItem}>
        <ListItemText
          primary={t("building_location")}
          secondary={getAppointmentAddress()}
          primaryTypographyProps={{ className: styles.primaryText }}
          secondaryTypographyProps={{ className: styles.secondaryText }}
        />
      </Grid>

      <Grid xs={4} item component={customListItem}>
        <ListItemText
          secondary={getAppointmentBuilding()}
          primary={t("buildings")}
          primaryTypographyProps={{ className: styles.primaryText }}
          secondaryTypographyProps={{ className: styles.secondaryText }}
        />
      </Grid>

      <Grid xs={4} item component={customListItem}>
        <ListItemText
          secondary={getAppointmentContact()}
          primary={t("building_contact_name")}
          primaryTypographyProps={{ className: styles.primaryText }}
          secondaryTypographyProps={{ className: styles.secondaryText }}
        />
      </Grid>

      <Grid xs={4} item component={customListItem}>
        <ListItemText
          secondary={getAppointmentContactEmail()}
          primary={t("email")}
          primaryTypographyProps={{ className: styles.primaryText }}
          secondaryTypographyProps={{ className: styles.secondaryText }}
        />
      </Grid>

      <Grid xs={12} item component={customListItem}>
        <ListItemText
          secondary={getAppointmentAdditionalInformation()}
          primary={t("building_additional_information")}
          primaryTypographyProps={{ className: styles.primaryText }}
          secondaryTypographyProps={{ className: styles.secondaryTextNoBold }}
        />
      </Grid>

      <Grid xs={12} item component={customListItem}>
        <ListItemText
          secondary={getAppointmentParkingSituation()}
          primary={t("building_parking_situation")}
          primaryTypographyProps={{ className: styles.primaryText }}
          secondaryTypographyProps={{ className: styles.secondaryTextNoBold }}
        />
      </Grid>
    </Grid>
  );
};

export default AppointmentTreeItemCompanyInformation;
