import { useEffect, useState } from "react";
import DepartmentTable from "./departmenttable";
import UserTable from "./userTable";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { t } from "i18next";
import FileUpload from "../files/fileUpload";
import DownloadButton from "../forms/inputs/downloadButton";
import "./userAdministration.css";
import CsvExport from "./csvExport";
import Button from "../forms/inputs/button";
import Modal from "../modal";
import CompanyManagerTable from "./companyManagerTable";
import styles from "./administration.module.css";

interface Props {
  companySubjectId?: string;
  companyId?: string;
  uploadSuccessful?: boolean;
  handleSendRegistrationEmail?: (
    row: any,
    skipQuestion?: false | undefined
  ) => void;
  handleUnblockUser?: (
    row: any,
    skipQuestion?: false | undefined
  ) => void;
  handleUndeleteUser?: (
    row: any,
    reset: () => void,
    skipQuestion?: false | undefined
  ) => void;
  subdomain?: string;
  handleUploadSuccess?: () => void;
  handleEntries?: (entries: any) => void;
  selectedChildCompany?: any;
}

export type Department = {
  departmentName: string;
  departmentId: string;
};

export type Expanded = {
  upload: boolean;
  department: boolean;
  user: boolean;
  companyManager: boolean;
};
const initialExpanded: Expanded = {
  upload: false,
  department: false,
  user: true,
  companyManager: false,
};

export default function Administration(props: Props) {
  const [department, setDepartment] = useState<Department[]>([]);
  const [expanded, setExpanded] = useState<Expanded>(initialExpanded);
  const [hasDepartments, setHasDepartments] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<string>("");
  const [csvFileExportTarget, setCsvFileExportTarget] = useState<string>("");

  useEffect(() => {
    if (!hasDepartments) {
      setExpanded({
        upload: false,
        department: true,
        user: false,
        companyManager: false,
      });
    }
  }, [hasDepartments]);
  const handleDepartmentForFilter = (row: any) => {
    setDepartment([
      {
        departmentName: row.original.name,
        departmentId: row.original.id,
      },
    ]);
  };
  const handleHasDepartments = () => {
    setHasDepartments(false);
  };

  const handleExpandedPanel = (panel: string) => {
    switch (panel) {
      case "upload":
        setExpanded({ ...expanded, upload: !expanded.upload });
        break;
      case "department":
        setExpanded({ ...expanded, department: !expanded.department });
        break;
      case "user":
        setExpanded({ ...expanded, user: !expanded.user });
        break;
      case "companyManager":
        setExpanded({ ...expanded, companyManager: !expanded.companyManager });
        break;
    }
  };

  const handleDeleteDepartment = () => {
    setDepartment([]);
  };

  function AdministrationFileUpload() {
    let content = <></>;
    if (props.companySubjectId && props.subdomain === "admin") {
      content = (
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DownloadButton
                className={styles.downloadButton}
                title={t("download_example_csv")}
                variant="contained"
                color="secondary"
                href={`/api/v1/user/example_worker_csv/?impersonate_subject=${props.companySubjectId}`}
                withTooltip={true}
                tooltipTitle={t("example_csv")}
                tooltipPlacement="top"
                textTransform={"none"}
              />
            </Grid>

            <Grid item xs={12}>
              <FileUpload
                title={t("choose_file_forupload_customer_csv")}
                type={"file"}
                label={""}
                name="file"
                entity="user"
                companySubjectId={props.companySubjectId}
                companyId={props.companyId}
                handleUploadSuccess={props.handleUploadSuccess}
                handleEntries={props.handleEntries}
                accept=".csv"
                onCancel={() => setModalOpen(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    } else if (props.subdomain === "manage") {
      content = (
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DownloadButton
                className={styles.downloadButton}
                variant="contained"
                color="secondary"
                title={t("download_example_csv")}
                href={
                  "/api/v1/user/example_worker_csv/?impersonate_subject=" +
                  String(props.selectedChildCompany?.subjectId)
                }
                withTooltip={true}
                tooltipTitle={t("example_csv")}
                tooltipPlacement="top"
                textTransform={"none"}
              />
            </Grid>
            <Grid item xs={12}>
              <FileUpload
                title={t("choose_file_forupload_customer_csv")}
                type={"file"}
                label={""}
                name="file"
                entity="user"
                handleUploadSuccess={props.handleUploadSuccess}
                handleEntries={props.handleEntries}
                accept=".csv"
                onCancel={() => setModalOpen(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return content;
  }
  const uploadExportHeading = () => {
    if (props.subdomain === "admin") {
      return t("csv_upload") + " / " + t("csv_export");
    } else return t("csv_upload");
  };
  let userHeading = <h1 className="tableHeading">{t("customer")}</h1>;
  if (department?.length !== 0) {
    userHeading = (
      <h1 className="tableHeading_withChip">
        {t("customer")}
        <Chip
          label={department?.[0].departmentName}
          onDelete={handleDeleteDepartment}
          className={styles.chip}
          classes={{ deleteIcon: styles.deleteIcon }}
        />
      </h1>
    );
  }

  const handleGetSelectedExportTemplate = (template: any) => {
    setCsvFileExportTarget(template);
  };

  const handleDownloadCsv = () => {
    let href = `/api/v1/user/export_worker_csv/?company_id=${props.companyId}&template=${csvFileExportTarget}`;
    if (department.length !== 0) {
      href += "&department=" + department[0].departmentId;
    }

    window.location.href = href;
  };

  return (
    <Grid container>
      <Grid item xs={12} className={styles.gridContainer}>
        <Accordion
          className={styles.accordion}
          expanded={expanded.department}
          onChange={() => handleExpandedPanel("department")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{ content: styles.accordionSummaryContent }}
          >
            {!expanded.department ? (
              t("departments")
            ) : (
              <h1 className="tableHeading">{t("departments")}</h1>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <DepartmentTable
              companySubjectId={props.companySubjectId}
              companyId={props.companyId}
              uploadSuccessful={props.uploadSuccessful}
              handleDepartmentForFilter={handleDepartmentForFilter}
              department={department}
              handleDeleteDepartment={handleDeleteDepartment}
              hasDepartments={handleHasDepartments}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={12} className={styles.gridContainer}>
        <Accordion
          className={styles.accordion}
          expanded={expanded.companyManager}
          onChange={() => handleExpandedPanel("companyManager")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{ content: styles.accordionSummaryContent }}
          >
            {!expanded.companyManager ? (
              t("manager")
            ) : (
              <h1 className="tableHeading">{t("manager")}</h1>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <CompanyManagerTable
              subjectId={props.companySubjectId}
              companyId={props.companyId}
              subdomain={props.subdomain}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12} className={styles.gridContainer}>
        <Accordion
          className={styles.accordion}
          expanded={expanded.upload}
          onChange={() => handleExpandedPanel("upload")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{ content: styles.accordionSummaryContent }}
          >
            {!expanded.upload ? (
              uploadExportHeading()
            ) : (
              <h1 className="tableHeading">{uploadExportHeading()}</h1>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Grid container columnSpacing={"1rem"}>
              <Grid item>
                <Button
                  title={"Mitarbeiterupload"}
                  variant="outlined"
                  onClick={() => {
                    setModalOpen(true), setModalMode("upload");
                  }}
                />
              </Grid>
              {props.subdomain === "admin" && (
                <Grid item>
                  <Button
                    title={"Csv-Export"}
                    variant="outlined"
                    onClick={() => {
                      setModalOpen(true), setModalMode("export");
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12} className={styles.gridContainer}>
        <Accordion
          className={styles.accordion}
          expanded={expanded.user}
          onChange={() => handleExpandedPanel("user")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{ content: styles.accordionSummaryContent }}
          >
            {!expanded.user ? t("customer") : userHeading}
          </AccordionSummary>
          <AccordionDetails>
            <UserTable
              companySubjectId={props.companySubjectId}
              companyId={props.companyId}
              uploadSuccessful={props.uploadSuccessful}
              handleSendRegistrationEmail={props.handleSendRegistrationEmail}
              handleUnblockUser={props.handleUnblockUser}
              department={department}
              handleDeleteDepartment={handleDeleteDepartment}
              handleUndeleteUser={props.handleUndeleteUser}
              expanded={expanded}
              handleUploadSuccess={props.handleUploadSuccess}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
      {modalMode && (
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          title={modalMode === "upload" ? t("csv_upload") : t("csv_export")}
          contentClassName={styles.modalContent}
          hideDialogActions={modalMode === "upload" ? true : false}
          buttonPlacement={modalMode === "export" ? "space-evenly" : null}
          submitButtonText={modalMode === "export" ? t("download") : null}
          onSubmit={handleDownloadCsv}
          disableSubmit={csvFileExportTarget !== "" ? false : true}
        >
          {modalMode === "upload" && <AdministrationFileUpload />}
          {modalMode === "export" && (
            <Grid container columnSpacing={"1rem"} rowSpacing={"1rem"}>
              {department.length !== 0 && (
                <Grid item xs={12}>
                  <Chip
                    label={department?.[0].departmentName}
                    onDelete={handleDeleteDepartment}
                    className={styles.chip}
                    classes={{ deleteIcon: styles.deleteIcon }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <CsvExport onChange={handleGetSelectedExportTemplate} />
              </Grid>
            </Grid>
          )}
        </Modal>
      )}
    </Grid>
  );
}
