import { useEffect, useState } from "react";

interface Props {
  defaultValue?: any;
  onChange?: (checked: boolean) => void;
  style?: React.CSSProperties;
  label?: any;
  name?: any;
  wrapperStyle?: React.CSSProperties;
  wrapperClassName?: string;
  labelClassName?: string;
}

export default function CheckBox(props: Props) {
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    if (props.defaultValue) {
      setChecked(props.defaultValue);
    }
  }, [props.defaultValue]);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(checked);
    }
  }, [checked]);

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <div
      className={props.wrapperClassName}
      style={{
        ...props.wrapperStyle,
      }}
    >
      <label
        className={props.labelClassName}
        style={props.style}
        htmlFor={props.name}
      >
        {props.label}
      </label>
      <input
        name={props.name}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        id={props.name}
      />
    </div>
  );
}
