import { FC } from "react";
import Restricted from "../consumer/restricted";
import PreventiveCareMainTable from "../components/preventiveCare/preventiveCareMainTable";

const PreventiveCareList: FC = () => {
  return (
    <Restricted permissionRequired="frontend_view_preventive_care">
      <PreventiveCareMainTable />
    </Restricted>
  );
};

export default PreventiveCareList;
