import { FC, useEffect, useState } from "react";
import { ListItem } from "@mui/material";
import "./navigationBar.css";
import { adminSubroutes, settingsSoubRoutes } from "./adminSubRoutes";
import {
  NavigationItemButton,
  NavigationListItemContent,
  NavigationNavLink,
} from "./navigationItems";
import { t } from "i18next";
import Restricted from "../../consumer/restricted";
import GenericNavigationCollapse from "./genericNavigationCollapse";
import GenericExpandableNavigationListItem from "./genericExpandableNavigationListItem";

interface Props {
  drawerOpen: boolean;
  selectedIndex: number;
  handleListItemClick?: any;
  handleListSoubrouteItemClick?: any;
  subdomain: string;
  subRouteToOpen: string;
}

const AdminRoutes: FC<Props> = ({
  drawerOpen,
  subdomain,
  selectedIndex,
  handleListItemClick,
  handleListSoubrouteItemClick,
  subRouteToOpen,
}) => {
  const [subroutesOpen, setSubroutesOpen] = useState({});

  const toggleSubrouteOpen = (routeId: any) => {
    setSubroutesOpen((prevState: any) => ({
      ...prevState,
      [routeId]: !prevState[routeId],
    }));
  };

  useEffect(() => {
    toggleSubrouteOpen(subRouteToOpen);
  }, [subRouteToOpen]);

  if (subdomain === "admin") {
    return (
      <>
        <Restricted permissionRequired="frontend_view_appointment_dashboard">
          <ListItem
            disablePadding
            className={drawerOpen ? "navigationItemOpen" : "navigationItem"}
          >
            <NavigationNavLink to="/appointmentDashboard">
              <NavigationItemButton
                drawerOpen={drawerOpen}
                onClick={(event: any) => handleListItemClick(event, 20)}
                selected={selectedIndex === 20}
              >
                <NavigationListItemContent
                  drawerOpen={drawerOpen}
                  primaryText="Termin Dashboard"
                  icon="appointmentDashboard"
                />
              </NavigationItemButton>
            </NavigationNavLink>
          </ListItem>
        </Restricted>
        <Restricted permissionRequired="frontend_view_appointment_request">
          <ListItem
            disablePadding
            className={drawerOpen ? "navigationItemOpen" : "navigationItem"}
          >
            <NavigationNavLink to="/appointmentrequest">
              <NavigationItemButton
                drawerOpen={drawerOpen}
                onClick={(event: any) => handleListItemClick(event, 21)}
                selected={selectedIndex === 21}
              >
                <NavigationListItemContent
                  drawerOpen={drawerOpen}
                  primaryText={t("appointmentrequest_single")}
                  icon="newAppointment"
                />
              </NavigationItemButton>
            </NavigationNavLink>
          </ListItem>
        </Restricted>
        <Restricted permissionRequired="frontend_view_appointment">
          <ListItem
            disablePadding
            className={drawerOpen ? "navigationItemOpen" : "navigationItem"}
          >
            <NavigationNavLink to="/appointmentList">
              <NavigationItemButton
                drawerOpen={drawerOpen}
                onClick={(event: any) => handleListItemClick(event, 26)}
                selected={selectedIndex === 26}
              >
                <NavigationListItemContent
                  drawerOpen={drawerOpen}
                  primaryText={t("appointments_booked")}
                  icon="bookedAppointments"
                />
              </NavigationItemButton>
            </NavigationNavLink>
          </ListItem>
        </Restricted>
        <Restricted permissionRequired="frontend_view_preventive_care">
          <ListItem
            disablePadding
            className={drawerOpen ? "navigationItemOpen" : "navigationItem"}
          >
            <NavigationNavLink to="/preventiveCareList">
              <NavigationItemButton
                drawerOpen={drawerOpen}
                onClick={(event: any) => handleListItemClick(event, 14)}
                selected={selectedIndex === 14}
              >
                <NavigationListItemContent
                  drawerOpen={drawerOpen}
                  primaryText={t("preventive_care")}
                  icon="preventiveCare"
                />
              </NavigationItemButton>
            </NavigationNavLink>
          </ListItem>
        </Restricted>
        <Restricted permissionRequired="frontend_view_subject_company_overview">
          <ListItem
            disablePadding
            className={drawerOpen ? "navigationItemOpen" : "navigationItem"}
          >
            <NavigationNavLink to="/subjectOverview">
              <NavigationItemButton
                drawerOpen={drawerOpen}
                onClick={(event: any) => handleListItemClick(event, 42)}
                selected={selectedIndex === 42}
              >
                <NavigationListItemContent
                  drawerOpen={drawerOpen}
                  primaryText={t("subject_company_overview")}
                  icon="subjectCompanyOverview"
                />
              </NavigationItemButton>
            </NavigationNavLink>
          </ListItem>
        </Restricted>

        <GenericExpandableNavigationListItem
          listItemIconName="admin"
          listItemLabel="Admin"
          drawerOpen={drawerOpen}
          handleSubrouteOpen={toggleSubrouteOpen}
          subRouteOpen={subroutesOpen}
        />
        <GenericNavigationCollapse
          routesToRender={adminSubroutes}
          drawerOpen={drawerOpen}
          selectedIndex={selectedIndex}
          subRouteOpen={subroutesOpen}
          handleListSoubrouteItemClick={handleListSoubrouteItemClick}
          listItemLabel="Admin"
        />
        <GenericExpandableNavigationListItem
          listItemIconName="admin"
          listItemLabel="Settings"
          drawerOpen={drawerOpen}
          handleSubrouteOpen={toggleSubrouteOpen}
          subRouteOpen={subroutesOpen}
        />
        <GenericNavigationCollapse
          routesToRender={settingsSoubRoutes}
          drawerOpen={drawerOpen}
          selectedIndex={selectedIndex}
          subRouteOpen={subroutesOpen}
          handleListSoubrouteItemClick={handleListSoubrouteItemClick}
          listItemLabel="Settings"
        />
      </>
    );
  } else return <></>;
};

export default AdminRoutes;
