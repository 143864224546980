import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "./inputs/button";
import { useNavigate } from "react-router-dom";
import { api } from "../../helper/api";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import PasswordInput from "./inputs/passwordInput";
import { useForm } from "react-hook-form";
import GenericErrorMessageModal from "./errorHandling/genericErrorMessageModal";

import styles from "./resetPasswordForm.module.css";

interface Props {
  title?: string;
}

const ResetPasswordForm: FC<Props> = () => {
  const [error, setError] = useState<any>();

  const { handleSubmit, control, watch } = useForm({
    mode: "onChange",
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });
  const newPassword = watch("newPassword");

  const passwordRegex = new RegExp(
    /^(?=.*[0-9])(?=.*[!=?\-_+/&$#*.,:;@]).{10,}$/
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = (data: any) => {
    const result = {
      current_password: data.currentPassword,
      new_password: data.newPassword,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      entity: "user/change_own_password",
      method: "put",
      successHandler: () => {
        navigate("/login");
      },
      failHandler: (error: any) => {
        setError(error);
      },
      submitData: result,
      config: config,
    });
  };

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{t("reset_password")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.formStyle}
            noValidate
          >
            <PasswordInput
              id={t("current_password")}
              label={t("current_password")}
              title={t("current_password")}
              dynamicLabel={false}
              control={control}
              name={"currentPassword"}
              required={true}
              requiredErrorMessage={t("field_required")}
              labelClassName={styles.passwordInput__label}
              inputClassName={styles.passwordInput__input}
            />
            <Divider component={"span"} />
            <PasswordInput
              id={t("new_password")}
              title={t("new_password")}
              // label={t("new_password")}
              dynamicLabel={false}
              control={control}
              name={"newPassword"}
              required={true}
              requiredErrorMessage={t("field_required")}
              pattern={passwordRegex}
              patternErrorMessage={t("password_requirements")}
              labelClassName={styles.passwordInput__label}
              inputClassName={styles.passwordInput__input}
              label={
                <div>
                  <span style={{ display: "block" }}>{t("new_password")} </span>
                  <span style={{ fontSize: "0.8rem" }}>
                    {t("password_requirements")}
                  </span>
                </div>
              }
            />

            <PasswordInput
              id={t("confirm_password")}
              label={t("confirm_password")}
              title={t("confirm_password")}
              dynamicLabel={false}
              control={control}
              name={"confirmNewPassword"}
              required={true}
              requiredErrorMessage={t("field_required")}
              validationRule={newPassword}
              validationRuleErrorMessage={t("password_dont_match")}
              labelClassName={styles.passwordInput__label}
              inputClassName={styles.passwordInput__input}
            />
            <Button type="submit" title={t("save")} />
          </form>
        </AccordionDetails>
      </Accordion>
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
};

export default ResetPasswordForm;
