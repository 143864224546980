import { useState, useEffect, FC } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";
import { KaerIcon } from "../icons/kaerIcon";
import RoundedContentBox from "../components/general/roundedContentBox";
import { BeatLoader } from "react-spinners";
import {
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import "./startPage.css";
import React from "react";
import { api } from "../helper/api";
import { useSelector } from "react-redux";
import { RootState } from "../components/state/store";
import AppointmentRequestSampleIcon from "../icons/appointmentRequestSampleIcon";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import GenericErrorMessageModal from "../components/forms/errorHandling/genericErrorMessageModal";

export default function StartPage() {
  const [todoCount, setTodoCount]: any = useState();
  const [appointmentRequestCount, setAppointmentRequestCount]: any = useState();
  const [workerCount, setWorkerCount] = useState<number>();
  const [departmentCount, setDepartmentCount] = useState<number>();
  const [newFilesCount, setNewFilesCount] = useState<number>();
  const [registeredWorkerCount, setRegisteredWorkerCount] = useState<number>();
  const [completedCategoryExaminations, setCompletedCategoryExaminations] =
    useState<any>([]);
  const [upComingAppointmentRequests, setUpcomingAppointmentRequests] =
    useState<any>([]);
  const [upComingAppointments, setUpcomingAppointments] = useState<any>([]);
  const [error, setError] = useState<any>();

  const { t } = useTranslation();
  const [firstName, setFirstName] = useState<string | undefined>("");

  const getAuth = useAuthUser();
  const isAuthenticated = useIsAuthenticated();
  const selectedSubject = useSelector((state: RootState) => state.parent);
  const children = useSelector((state: RootState) => state.children);
  const theme = useTheme();
  const breakpointSm = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    if (isAuthenticated()) {
      const auth = getAuth();

      if (auth && auth.email) {
        setFirstName(auth.first_name);
      }
    }
  }, [getAuth, isAuthenticated]);

  const appointmentLocation = (item: any) => {
    const { name, street_house, zip, city } = item.building_information;

    const locationParts = [];

    if (name) locationParts.push(name);
    if (street_house) locationParts.push(street_house);

    if (zip || city) {
      const combined = [zip, city].filter((part) => part !== null).join(" ");
      locationParts.push(combined);
    }

    return locationParts.join(", ");
  };

  useEffect(() => {
    api.genericApiRequest({
      entity: "dashboard",
      method: "get",
      successHandler: (res: any) => {
        if (
          res.data &&
          res.data.completed_category_examinations !== undefined
        ) {
          setCompletedCategoryExaminations(
            res.data.completed_category_examinations
          );
        }
        if (res.data && res.data.worker_count) {
          setWorkerCount(res.data.worker_count);
        } else {
          setWorkerCount(0);
        }
        if (res.data && res.data.department_count) {
          setDepartmentCount(res.data.department_count);
        } else {
          setDepartmentCount(0);
        }
        if (res.data && res.data.new_files) {
          setNewFilesCount(res.data.new_files);
        } else {
          setNewFilesCount(0);
        }
        if (res.data && res.data.registered_worker_count) {
          setRegisteredWorkerCount(res.data.registered_worker_count);
        } else {
          setRegisteredWorkerCount(0);
        }
        if (res.data && res.data.todo_count) {
          setTodoCount(res.data.todo_count);
        } else {
          setTodoCount(0);
        }
        if (res.data && res.data.appointment_request_count) {
          setAppointmentRequestCount(res.data.appointment_request_count);
        } else {
          setAppointmentRequestCount(0);
        }
        if (res.data && res.data.upcoming_appointment_requests !== undefined) {
          setUpcomingAppointmentRequests(
            res.data.upcoming_appointment_requests
          );
        }
        if (res.data && res.data.upcoming_appointments !== undefined) {
          setUpcomingAppointments(res.data.upcoming_appointments);
        }
      },
      failHandler: (error: any) => {
        setTodoCount(0);
        setAppointmentRequestCount(0);
        setWorkerCount(0);
        setRegisteredWorkerCount(0);
        setDepartmentCount(0);
        setNewFilesCount(0);
        setError(error);
      },
    });
  }, [selectedSubject.subjectId, children]);

  let todoCounterContentBox = <BeatLoader />;
  let appointmentRequestCounterContentBox = <BeatLoader />;
  let registeredWorkerCountContentBox = <BeatLoader />;
  let statisticContentBox = <BeatLoader />;
  let contactContentBox = <BeatLoader />;
  let completedCategoryExaminationContentBox = <BeatLoader />;
  let upcomingApointmentRequestsContentBox = <BeatLoader />;

  //statisticList
  let workerCountContentBox = <></>;
  let departmentCountContentBox = <></>;
  let newFilesCountContentBox = <></>;

  let toDoString = t("todo_single");
  if (todoCount === 0 || todoCount > 1) {
    toDoString = t("todo_multi");
  }

  let appointmentRequestString = t("appointmentrequest_single");
  if (appointmentRequestCount === 0 || appointmentRequestCount > 1) {
    appointmentRequestString = t("appointmentrequest_multi");
  }

  if (todoCount !== undefined) {
    todoCounterContentBox = (
      <section className="contentSection">
        <KaerIcon width="9.4%" height="9.4%" color="#8C1EC8" icon="todo" />
        <h2 className="sectionHeading">
          {todoCount} {toDoString}
        </h2>
      </section>
    );
  }
  if (appointmentRequestCount !== undefined) {
    appointmentRequestCounterContentBox = (
      <section className="contentSection">
        <KaerIcon
          width="9.4%"
          height="9.4%"
          color="#FF6428"
          icon="newAppointment"
        />
        <h2 className="sectionHeading">
          {appointmentRequestCount} {appointmentRequestString}
        </h2>
      </section>
    );
  }

  let appointmentRequestsBox = <></>;
  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  if (subdomain != "admin") {
    appointmentRequestsBox = (
      <NavLink className="dashboardNavlink" to="/appointmentrequestopen">
        <RoundedContentBox>
          {appointmentRequestCounterContentBox}
        </RoundedContentBox>
      </NavLink>
    );
  }

  let upcomingAppointmentRequestListItems = <></>;
  let upcomingAppointmentListItem = <></>;
  if (upComingAppointmentRequests.length !== 0) {
    upcomingAppointmentRequestListItems = upComingAppointmentRequests.map(
      (item: any) => {
        return (
          <React.Fragment key={item.title + item.start_day + Math.random()}>
            <ListItem
              className="requestListItem"
              sx={{
                "&& .MuiListItemSecondaryAction-root": {
                  position: "relative",
                  paddingLeft: "2rem",
                },
              }}
              secondaryAction={
                breakpointSm && dayjs(item.start_day).format("DD.MM.YYYY")
              }
            >
              <ListItemIcon className="requestIconWrapper">
                <AppointmentRequestSampleIcon
                  width="2.5rem"
                  height="2.5rem"
                  withBackground={true}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  breakpointSm
                    ? item.title
                    : item.title +
                      ` - ${dayjs(item.start_day).format("DD.MM.YYYY")}`
                }
                secondary={item.department_set.map(
                  (department: any, index: any) => {
                    const length = item.department_set.length - 1;
                    return index !== length
                      ? department.name + ", "
                      : department.name;
                  }
                )}
              />
            </ListItem>
            <div className="progressBarWrapper">
              <LinearProgress
                variant="determinate"
                value={Math.round(
                  (100 / item.invited_user_count) * item.accepted_user_count
                )}
                className="appointmentRequestProgressBar"
                classes={{ bar: "linearProgressBar" }}
              />
              <Typography
                variant="caption"
                pt={2}
                className="progressBarText"
              >{`Angemeldet: ${item.accepted_user_count} / ${item.invited_user_count}`}</Typography>
            </div>
          </React.Fragment>
        );
      }
    );
  }
  if (upComingAppointments.length !== 0) {
    upcomingAppointmentListItem = upComingAppointments.map((item: any) => {
      return (
        <React.Fragment key={item.datetime}>
          <ListItem
            className={`
              requestListItem__portal
              ${breakpointSm ? "requestListItemPadding" : "noPadding"}
            `}
            sx={{
              "&& .MuiListItemSecondaryAction-root": {
                paddingLeft: "2rem",
              },
            }}
            secondaryAction={
              breakpointSm && dayjs(item.datetime).format("DD.MM.YYYY")
            }
          >
            <ListItemIcon className="requestIconWrapper">
              <AppointmentRequestSampleIcon
                width="2.5rem"
                height="2.5rem"
                withBackground={true}
              />
            </ListItemIcon>
            <ListItemText
              secondaryTypographyProps={{ component: "div" }}
              primary={
                <>
                  {breakpointSm
                    ? item.category_examinations
                    : item.category_examinations +
                      ` - ${dayjs(item.datetime).format("DD.MM.YYYY")}`}
                </>
              }
              secondary={<ListItemText primary={appointmentLocation(item)} />}
            />
          </ListItem>
        </React.Fragment>
      );
    });
  }

  upcomingApointmentRequestsContentBox = (
    <>
      <h2 className="secondHeading">Kommende Termine</h2>
      {Array.isArray(upcomingAppointmentListItem) && (
        <List
          aria-labelledby="appointmentSubheader"
          subheader={
            subdomain !== "portal" ? (
              <ListSubheader component="div" id="appointmentSubheader">
                {t("appointment_multi")}
              </ListSubheader>
            ) : null
          }
        >
          {upcomingAppointmentListItem}
        </List>
      )}
      {Array.isArray(upcomingAppointmentRequestListItems) && (
        <List
          aria-labelledby="appointmentRequestSubheader"
          subheader={
            <ListSubheader component="div" id="appointmentRequestSubheader">
              {t("appointmentrequest_multi")}{" "}
            </ListSubheader>
          }
        >
          {upcomingAppointmentRequestListItems}
        </List>
      )}
    </>
  );

  if (completedCategoryExaminations.length !== 0) {
    completedCategoryExaminationContentBox = (
      <section className="completedExaminationWrapper">
        <div className="completedExaminationsStringWrapper">
          <h2 className="secondHeading ">
            {t("completed_category_examinations")}
          </h2>
          <span className="completedExaminationsSpan bold">
            {completedCategoryExaminations.reduce(
              (total: any, item: any) => total + item.count,
              0
            )}
          </span>
        </div>
        <List className="fullWidth">
          {completedCategoryExaminations.map((item: any) => (
            <React.Fragment key={item.examination_name}>
              <ListItem
                className="countListItem"
                secondaryAction={item.count.toString()}
              >
                <ListItemText
                  primary={item.examination_name}
                  primaryTypographyProps={{
                    paddingRight: 8,
                  }}
                  className="countListItemText"
                />
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </section>
    );
  }

  if (newFilesCount !== undefined) {
    newFilesCountContentBox = (
      <ListItem
        className="countListItem"
        secondaryAction={newFilesCount.toString()}
      >
        <ListItemText
          primary={t("new_files")}
          primaryTypographyProps={{
            paddingRight: 8,
          }}
          className="countListItemText"
        />
      </ListItem>
    );
  }

  if (departmentCount !== undefined) {
    departmentCountContentBox = (
      <ListItem
        className="countListItem"
        secondaryAction={departmentCount.toString()}
      >
        <ListItemText
          primary={t("departments")}
          primaryTypographyProps={{
            paddingRight: 8,
          }}
          className="countListItemText"
        />
      </ListItem>
    );
  }

  if (workerCount !== undefined && registeredWorkerCount !== undefined) {
    workerCountContentBox = (
      <ListItem
        className="countListItem"
        secondaryAction={workerCount.toString()}
      >
        <ListItemText
          primary={t("customer")}
          primaryTypographyProps={{
            paddingRight: 8,
          }}
          className="countListItemText"
        />
      </ListItem>
    );
  }

  if (subdomain === "manage") {
    statisticContentBox = (
      <>
        <h2 className="secondHeading">Statistik</h2>
        <List
          sx={{
            "&& .MuiListItem-root:hover, && .MuiTypography-root:hover": {
              color: "#000000",
              fontWeight: "bold",
            },
          }}
        >
          <NavLink className="dashboardNavlinkList" to="/mitarbeiter">
            {departmentCountContentBox}
          </NavLink>
          <NavLink className="dashboardNavlinkList" to="/mitarbeiter">
            {workerCountContentBox}
          </NavLink>
          <NavLink className="dashboardNavlinkList" to="/document">
            {newFilesCountContentBox}
          </NavLink>
        </List>
      </>
    );
  }

  let statisticBox = <></>;
  if (subdomain === "manage") {
    statisticBox = (
      <RoundedContentBox className="fullHeight">
        {statisticContentBox}
      </RoundedContentBox>
    );
  }
  if (subdomain === "manage" || subdomain === "portal") {
    contactContentBox = (
      <section>
        <a className="contactLink" href="mailto:service@kaerhealth.com">
          <KaerIcon icon="mail" width="9.4%" height="9.4%" color="#FF6428" />
          <Grid container component={"div"} className="alignCenter">
            <Grid item xs={"auto"} className="marginLeft">
              <h2 className="secondHeading noMarginTop">{t("contact")}</h2>
            </Grid>
            <Grid container item sm={"auto"} className="contactText">
              <Grid item>
                <p className="noMargin bold">{t("click_contact_text")}</p>
              </Grid>
              <Grid item>
                <p className="noMargin">{t("dashboard_phone_text")}</p>
              </Grid>
            </Grid>
          </Grid>
        </a>
      </section>
    );
  }
  let contactBox = <></>;
  if (subdomain === "manage" || subdomain === "portal") {
    contactBox = (
      <RoundedContentBox className="roundedContentBox">
        {contactContentBox}
      </RoundedContentBox>
    );
  }
  if (workerCount !== undefined && registeredWorkerCount !== undefined) {
    registeredWorkerCountContentBox = (
      <section className="registeredWorkerSection">
        <h2 className="secondHeading">Mitarbeiter</h2>
        <LinearProgress
          value={Math.round((100 / workerCount) * registeredWorkerCount)}
          variant="determinate"
          className="registeredWorkersProgressBar"
          classes={{ bar: "linearProgressBar" }}
        />
        <Typography
          variant="body2"
          pt={2}
          className="registeredWorkersText"
        >{`Registriert: ${registeredWorkerCount} / ${workerCount}`}</Typography>
      </section>
    );
  }

  let registeredWorkerCountBox = <></>;
  if (subdomain === "manage") {
    registeredWorkerCountBox = (
      <NavLink className="dashboardNavlink" to="/mitarbeiter">
        <RoundedContentBox className="roundedContentBox">
          {registeredWorkerCountContentBox}
        </RoundedContentBox>
      </NavLink>
    );
  }
  // Disabled on live - left in code for future usage
  /*eslint-disable */
  let completedCategoryExaminationBox = <></>;
  if (subdomain === "manage" && completedCategoryExaminations.length !== 0) {
    completedCategoryExaminationBox = (
      <Grid item xs={12} sm={12} md={12}>
        <RoundedContentBox className="roundedContentBox">
          {completedCategoryExaminationContentBox}
        </RoundedContentBox>
      </Grid>
    );
  }
  /*eslint-enable */

  let upcomingAppointmentRequestsBox = <></>;
  if (
    (subdomain === "manage" && upComingAppointmentRequests.length !== 0) ||
    upComingAppointments.length !== 0
  ) {
    upcomingAppointmentRequestsBox = (
      <RoundedContentBox>
        {upcomingApointmentRequestsContentBox}
      </RoundedContentBox>
    );
  }

  const PortalHeading: FC = () => {
    let heading;
    switch (subdomain) {
      case "admin":
        heading =
          t("hello") +
          " " +
          firstName +
          ", " +
          t("kaer_welcome", { portalName: t("staff") });
        break;
      case "manage":
        heading =
          t("hello") +
          " " +
          firstName +
          ", " +
          t("kaer_welcome", { portalName: t("manager") });
        break;
      default:
        heading =
          t("hello") +
          " " +
          firstName +
          ", " +
          t("kaer_welcome", { portalName: t("customer") });
        break;
    }

    return <p className="noMargin">{heading}</p>;
  };
  return (
    <>
      <Grid container marginBottom={6} rowSpacing={"1rem"}>
        <Grid item xs={12} mt={"1rem"}>
          <RoundedContentBox className="portalHeading">
            <PortalHeading />
          </RoundedContentBox>
        </Grid>
        <Grid container item columnSpacing={"1rem"} rowSpacing={"1rem"}>
          <Grid item xs={12} sm={12} md={6}>
            <NavLink className="dashboardNavlink" to="/todo">
              <RoundedContentBox>{todoCounterContentBox}</RoundedContentBox>
            </NavLink>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {appointmentRequestsBox}
          </Grid>
        </Grid>

        <Grid container item columnSpacing={"1rem"} rowSpacing={"1rem"}>
          {subdomain !== "portal" ? (
            <Grid item xs={12} sm={12} md={12} lg={6}>
              {statisticBox}
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={6}>
              {upcomingAppointmentRequestsBox}
            </Grid>
          )}

          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            rowSpacing={{ xs: 2 }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={subdomain === "portal" ? "fullHeight" : ""}
            >
              {contactBox}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {registeredWorkerCountBox}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item rowSpacing={"1rem"} columnSpacing={"1rem"}>
          {subdomain !== "portal" && (
            <Grid item xs={12} sm={12} md={12} lg={6}>
              {upcomingAppointmentRequestsBox}
            </Grid>
          )}
          {/* <Grid item xs={12} sm={12} md={12} lg={6}>
            {completedCategoryExaminationBox}
          </Grid> */}
        </Grid>
      </Grid>
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
}
