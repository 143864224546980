import { IconButton, ListItem } from "@mui/material";
import React, { FC } from "react";
import {
  NavigationIcon,
  NavigationItemButton,
  NavigationListItemContent,
} from "./navigationItems";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import styles from "./adminRoutes.module.css";
import "./navigationBar.css";

interface GenericExpandableNavigationListItemProps {
  drawerOpen: boolean;
  handleSubrouteOpen: (routeId: any) => void;
  subRouteOpen: any;
  listItemLabel: string;
  listItemIconName: NavigationIcon;
}

const GenericExpandableNavigationListItem: FC<
  GenericExpandableNavigationListItemProps
> = ({
  drawerOpen,
  handleSubrouteOpen,
  subRouteOpen,
  listItemLabel,
  listItemIconName,
}) => {
  return (
    <ListItem
      disablePadding
      className={drawerOpen ? "navigationItemOpen" : "navigationItem"}
    >
      <NavigationItemButton
        drawerOpen={drawerOpen}
        onClick={() => handleSubrouteOpen(listItemLabel.toLowerCase())}
        pr={drawerOpen ? 1 : 0}
      >
        <NavigationListItemContent
          drawerOpen={drawerOpen}
          primaryText={listItemLabel}
          icon={listItemIconName}
        />
        {drawerOpen && !subRouteOpen[listItemLabel.toLowerCase()] && (
          <IconButton className={styles.iconButtonStyles}>
            <ExpandMore />
          </IconButton>
        )}
        {drawerOpen && subRouteOpen[listItemLabel.toLowerCase()] && (
          <IconButton className={styles.iconButtonStyles}>
            <ExpandLess />
          </IconButton>
        )}
      </NavigationItemButton>
    </ListItem>
  );
};

export default GenericExpandableNavigationListItem;
