import { FC } from "react";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import "./navigationBar.css";
import { NavLink } from "react-router-dom";
import { KaerIcon } from "../../icons/kaerIcon";

interface Props {
  drawerOpen: boolean;
  children?: any;
  onClick?: any;
  selected?: any;
  pr?: number;
  pl?: number;
}

const NavigationItemButton: FC<Props> = ({
  drawerOpen,
  children,
  onClick,
  selected,
  pr,
  pl,
}) => {
  return (
    <ListItemButton
      selected={selected}
      onClick={onClick}
      className="navigationButton"
      sx={{
        minHeight: 48,
        justifyContent: drawerOpen ? "initial" : "center",
        padding: 0,
        pr: pr,
        pl: pl,
      }}
    >
      {children}
    </ListItemButton>
  );
};

interface NavLinkProps {
  to: string;
  children?: any;
}

const NavigationNavLink: FC<NavLinkProps> = ({ to, children }) => {
  return (
    <NavLink
      className={({ isActive, isPending }) =>
        isPending
          ? "navigationNavlink"
          : isActive
          ? "navigationNavlinkActive"
          : "navigationNavlink"
      }
      to={to}
    >
      {children}
    </NavLink>
  );
};

export type NavigationIcon =
  | "admin"
  | "appointments"
  | "appointmentDashboard"
  | "auditLog"
  | "bookedAppointments"
  | "building"
  | "company"
  | "coreData"
  | "document"
  | "employee"
  | "examinationList"
  | "folder"
  | "home"
  | "mail"
  | "mainCategory"
  | "measurementDefinition"
  | "newAppointment"
  | "permissions"
  | "permissionGroups"
  | "preventiveCare"
  | "probe"
  | "rules"
  | "subjectCompanyOverview"
  | "teams"
  | "template"
  | "todo"
  | "todoCreator"
  | "todoCrudTable";

interface NavigationListItemContentProps {
  icon: NavigationIcon;
  primaryText: string;
  drawerOpen?: boolean;
}

const NavigationListItemContent: FC<NavigationListItemContentProps> = ({
  icon,
  primaryText,
  drawerOpen,
}) => {
  return (
    <div>
      <Tooltip
        title={primaryText}
        arrow
        placement="right"
        disableHoverListener={drawerOpen}
      >
        <ListItemIcon
          className="navLinkIcon"
          sx={{
            minWidth: 0,
            mr: drawerOpen ? 2 : "0",
            justifyContent: "center",
            paddingLeft: drawerOpen ? "1rem" : "0",
            verticalAlign: "middle",
          }}
        >
          <KaerIcon icon={icon} />
        </ListItemIcon>
      </Tooltip>

      <ListItemText
        disableTypography
        primary={primaryText}
        sx={{ display: drawerOpen ? "inline-block" : "none" }}
      />
    </div>
  );
};

export { NavigationItemButton, NavigationNavLink, NavigationListItemContent };
